import { types } from "actions/corefilings";
const INITIAL_STATE = {
    isFetchingPipelines: false,    
    isFetchingProcessors: false,
    isFetchingSchemaRefs: false,
    isGeneratingTemplate: false,
    isPopulatingPipeline: false,
    isPopulatingDatapoint: false,
    pipelines: [],
    processors: [],
    schemaRefs: [],
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_SCHEMA_REFS_REQUEST:
            return {
                ...state,
                isFetchingSchemaRefs: true,
            }
        case types.GET_SCHEMA_REFS_SUCCEED:
            return {
                ...state,
                schemaRefs: action.payload.schemaRefs,
                isFetchingSchemaRefs: false,
            }
        case types.GET_SCHEMA_REFS_FAILURE:
            return {
                ...state,
                isFetchingSchemaRefs: false,
            }
        case types.GET_PIPELINES_REQUEST:
            return {
                ...state,
                isFetchingPipelines: true
            }
        case types.GET_PIPELINES_SUCCEED:
            return {
                ...state,
                pipelines: action.payload.pipelines,
                isFetchingPipelines: false
            }
        case types.GET_PIPELINES_FAILURE:
            return {
                ...state,
                isFetchingPipelines: false
            }
        case types.GET_PROCESSORS_REQUEST:
            return {
                ...state,
                isFetchingProcessors: true
            }
        case types.GET_PROCESSORS_SUCCEED:
            return {
                ...state,
                processors: action.payload.processors,
                isFetchingProcessors: false
            }
        case types.GET_PROCESSORS_FAILURE:
            return {
                ...state,
                isFetchingProcessors: false
            }
        case types.GENERATE_TEMPLATE_REQUEST:
            return {
                ...state,
                isGeneratingTemplate: true
            }
        case types.GENERATE_TEMPLATE_SUCCEED:
            return {
                ...state,
                isGeneratingTemplate: false
            }
        case types.GENERATE_TEMPLATE_FAILURE:
            return {
                ...state,
                isGeneratingTemplate: false
            }
        case types.POPULATE_PIPELINE_REQUEST:
            return {
                ...state,
                isPopulatingPipeline: true
            }
        case types.POPULATE_PIPELINE_SUCCEED:
            return {
                ...state,
                isPopulatingPipeline: false
            }
        case types.POPULATE_PIPELINE_FAILURE:
            return {
                ...state,
                isPopulatingPipeline: false
            }
        case types.POPULATE_DATAPOINT_REQUEST:
            return {
                ...state,
                isPopulatingDatapoint: true
            }
        case types.POPULATE_DATAPOINT_SUCCEED:
            return {
                ...state,
                isPopulatingDatapoint: false
            }
        case types.POPULATE_DATAPOINT_FAILURE:
            return {
                ...state,
                isPopulatingDatapoint: false
            }
        default:
            return state;
    }
}