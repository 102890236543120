import React from 'react';

import {
    ProgressBar
} from "components"
 
import styles from "./styles.module.css";
import 'font-awesome/css/font-awesome.min.css';

export default function FileIcon({
    file,
    onDelete: deleteFile,
    onRetry: retryUpload,
    status,
    progress,
}) {
    const handleDeleteFile = () => {
        deleteFile(file.name);
    }

    const handleRetry = () => {
        retryUpload(file.name, file);
    }

    const renderButton = () => {
        switch (status) {
            case undefined: {
                return (
                    !progress ? (
                        <button
                            disabled={ progress }
                            onClick={ handleDeleteFile }
                            className={ styles.button }
                        >
                            <i className="fa fa-times-circle" />
                        </button>
                    )
                    :
                    null
                )
            }
            case "SUCCEED": {
                return (
                    <i className="fa fa-check" />
                )
            }
            default: {
                return (
                    <button
                        onClick={ handleDeleteFile }
                        className={ styles.button }
                    >
                        <i className="fa fa-times-circle" />
                    </button>
                )                
            }
        }
    }

    return (
        <div className={ styles.component }>
            <div className={ styles.icon }>
                <i className={ `fa fa-file` } />
            </div>
            <div className={ styles.content }>
                <p className={ styles.name } title={ file.name }>
                    { file.name } 
                </p>
                <div className={ styles.feedback }>
                    {
                        status === "ERROR" ? (
                            <div>
                                <span>There was an error.</span>
                                <button
                                    onClick={ handleRetry }
                                    className={ styles.retry }
                                >
                                    Try again
                                </button>
                            </div>
                        ) : (
                            <ProgressBar
                                value={ progress }
                            />
                        )
                    }
                </div>
            </div>
            <div
                className={ styles.trash }
            >
                { renderButton() }
            </div>
        </div>
    );
}
