import React from 'react';
import { Card } from 'react-bootstrap';

const POSSIBLE_CATEGORIES = {
    "Upload": {
        text: "Inspect errors and output related to your uploads",
        type: "upload-log-box",
        iconClassName: "log-icon fa fa-upload fa-5x"
    },
    "Delete report": {
        text: "Inspect errors and output related to your deleted reports",
        type: "delete-log-box",
        iconClassName: "log-icon fa fa-trash fa-5x"
    },
    "Generate XBRL": {
        text: "Inspect errors and output related to your XBRL reports",
        type: "generate-xbrl-log-box",
        iconClassName: "log-icon fa fa-file fa-5x"
    },
    "Generate Excel": {
        text: "Inspect errors and output related to your XBRL reports",
        type: "generate-excel-log-box",
        iconClassName: "log-icon fa fa-file fa-5x"
    },
    "default": {
        text: "Inspect errors and output related to your miscellaneous logs",
        type: "",
        iconClassName: "log-icon fa fa-database fa-5x"
    }
}

const getCategoryData = category => {
    return POSSIBLE_CATEGORIES[category] ? POSSIBLE_CATEGORIES[category] : POSSIBLE_CATEGORIES.default;
}

export default function LogCategory({
    category,
    selectCategory
})
{
    const isSkeleton = category === 'Skeleton';
    const { text, type, iconClassName } = !isSkeleton ? getCategoryData(category) : {};

    return (
        <>
            {
                !isSkeleton && (
                    <Card onClick={() => selectCategory(category)} className={"generic-log-box " + type} style={{ width: '20rem' }}>
                        <Card.Body>
                            <Card.Title>{category}</Card.Title>
                            <Card.Text>{text}</Card.Text>
                        </Card.Body>
                        <i className={ iconClassName }></i>
                    </Card>
                )
            }

            {
                isSkeleton && (
                    <Card className="generic-log-box" style={{ width: '20rem' }}>
                        <Card.Body>
                            <Card.Title>
                                <div className="grey-shimmer rectangle-header"></div>
                            </Card.Title>
                            <Card.Text>
                                <span className="grey-shimmer rectangle-body"></span>
                            </Card.Text>
                        </Card.Body>
                        <div className="grey-shimmer log-icon rectangle-icon"></div>
                    </Card>
                )
            }
        </>
    )
}