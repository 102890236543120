import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Components
import { Header, Button } from "components";

// Actions
import * as actionsTenants from "actions/admin/tenants";

// Assets
import styles from "../styles.module.css";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function TenantView({id}) {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionsTenants.getTenant(id));
    }, [])

    const {
        name,
        server,
        databaseName,
        userName
    } = useSelector(state => state.admin.tenants.tenant);


    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="View Tenant"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/tenants') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>
                }
            />

            <form className={ styles.form }>
                <div className={styles.formControl}>
                    <label htmlFor="name">Name</label>
                    <input 
                        type='text'
                        value={name}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="server">Server</label>
                    <input
                        type='text'
                        value={server}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="databaseName">Database</label>
                    <input
                        type='text'
                        value={databaseName}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="userName">Username</label>
                    <input
                        type='text'
                        value={userName}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
            </form>
        </main>
    )
}