export const replaceItemAtIndex = (acc, item, index) => {
    return [
        ...acc.slice(0, index),
        item,
        ...acc.slice(index + 1)
    ];
}

export const removeItemAtIndex = (acc, index) => {
    return [
        ...acc.slice(0, index),
        ...acc.slice(index + 1)
    ];
}

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};
