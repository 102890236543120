import { types } from "actions/reports";

const INITIAL_STATE = {
    isFetching: false,
    isDownloading: false,
    isGeneratingReport: false,
    error: undefined,
    reports: [],
    uniqueReports: [],
    list: [],
    byName: {},
    idsBeingDeleted: [],
    selectedPeriod: undefined,
    periods: [],
    isUploadReportModalOpen: false,
    filenameHistorySelected: undefined,
    versionDetails: {
        versions: [],
        type: undefined,
        period: undefined,
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.TOGGLE_FILE_HISTORY:
            const { type, period } = action.payload;
            const versions = getVersions(state.reports, type, period);
            return {
                ...state,
                versionDetails: {
                    versions,
                    type,
                    period
                }
            }
        case types.OPEN_UPLOAD_MODAL:
            return {
                ...state,
                isUploadReportModalOpen: true,
            }
        case types.CLOSE_UPLOAD_MODAL:
            return {
                ...state,
                isUploadReportModalOpen: false,
            }            
        case types.GET_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }            
        case types.GET_LIST_RECEIVE:
            const reports = action.payload.data;
            const selectedEntity = sessionStorage.getItem('entityId');
            const uniqueReports = getUniqueReports(reports, selectedEntity)
            const periods = getPeriods(uniqueReports, state.selectedPeriod)
            const latestSelectedPeriod = state.selectedPeriod ? state.selectedPeriod : periods[0];
            return {
                ...state,
                isFetching: false,
                reports: reports,
                uniqueReports: uniqueReports,
                list: filterReportsByPeriod(uniqueReports, latestSelectedPeriod),
                byName: getReportsByName(uniqueReports),
                periods: periods,
            }            
        case types.GET_LIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            }

        case types.DELETE_ITEM_REQUEST:
            return {
                ...state,
                idsBeingDeleted: [...state.idsBeingDeleted, action.payload.id]
            }
        case types.DELETE_ITEM_SUCCEED:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id)
            }
        case types.DELETE_ITEM_FAILURE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id)
            }
        case types.DOWNLOAD_ITEM_REQUEST:
            return {
                ...state,
                isDownloading: true,
            }
        case types.DOWNLOAD_ITEM_SUCCEED:
            return {
                ...state,
                isDownloading: false,
            }
        case types.DOWNLOAD_ITEM_FAILURE:
            return {
                ...state,
                isDownloading: false,
                error: action.payload.error
            }
        case types.GENERATE_ITEM_REQUEST:
            return {
                ...state,
                isGeneratingReport: true,
            }
        case types.GENERATE_ITEM_SUCCEED:
            return {
                ...state,
                isGeneratingReport: false
            }
        case types.GENERATE_ITEM_FAILURE:
            return {
                ...state,
                isGeneratingReport: false,
                error: action.payload.error
            }
        case types.CHANGE_SELECTED_PERIOD:
            return {
                ...state,
                selectedPeriod: action.payload.period,
                list: filterReportsByPeriod(state.uniqueReports, action.payload.period)
            }
        default:
            return state;
    }
}

const getUniqueReports = (reports, entity) => {
    let finalReports = {};
    reports.map(report => {
		let str = report.type + report.period.replace(":", "");
		finalReports[str] = report
    })
    const results = Object.values(finalReports).filter(report => report.entityId === entity)
    
    return results
}

const filterReportsByPeriod = (reports, period) => {
    if (!period) {
        return reports;
    }
    return reports.filter(report => report.period === period)
}

const getReportsByName = (reports) => {
    return reports.reduce((acc, report) => {
        if (acc[report.filename]) {
            return {
                ...acc,
                [report.filename]: [
                    ...acc[report.filename],
                    report
                ]
            }
        }
        return {
            ...acc,
            [report.filename]: [
                report
            ]
        }
    }, {});    
}

const getPeriods = (reports, selectedPeriod) => {
    const period = [];
    if(selectedPeriod){
        period.push(selectedPeriod)
    }

    return reports.reduce((acc, report) => {
        if (acc.includes(report.period)) {
            return acc;
        }
        if (!report.period) {
            return acc;
        }

        return [
            ...acc,
            report.period,
        ]
    }, period).reverse()
}

const getVersions = (reports, type, period) => {
    if (!period & !type) {
        return reports;
    }
    return reports.filter(report => report.type === type && report.period === period)
}