import api from 'config/api';
import { navigate } from "@reach/router"

// Types
export const types = {
    GET_MAPPINGS_REQUEST: "mappings/getMappingsRequest",
    GET_MAPPINGS_RECEIVE: "mappings/getMappingsReceive",
    GET_MAPPINGS_FAILURE: "mappings/getMappingsFailure",

    CREATE_MAPPING_REQUEST: "mappings/createMappingRequest",
    CREATE_MAPPING_RECEIVE: "mappings/createMappingReceive",
    CREATE_MAPPING_FAILURE: "mappings/createMappingFailure",

    DELETE_MAPPING_REQUEST: "mappings/deleteMappingRequest",
    DELETE_MAPPING_RECEIVE: "mappings/deleteMappingReceive",
    DELETE_MAPPING_FAILURE: "mappings/deleteMappingFailure",
}

// Actions
export const getMappingsRequest = () => ({ type: types.GET_MAPPINGS_REQUEST, });

export const getMappingsReceive = mappings => ({
    type: types.GET_MAPPINGS_RECEIVE,
    payload: {
        mappings,
    }
});

export const getMappingsFailure = error => ({
    type: types.GET_MAPPINGS_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getMappings = () => (dispatch) => {
    dispatch(getMappingsRequest());

    api.get(api.defaults.ADMIN_MAPPING)
        .then((response) => {
            const mappings = response.data;
            dispatch(getMappingsReceive(mappings));
        })
        .catch(error => {
            dispatch(getMappingsFailure(error.message));
        })
}

// Actions
export const createMappingRequest = () => ({ type: types.CREATE_MAPPING_REQUEST, });
export const createMappingReceive = () => ({ type: types.CREATE_MAPPING_RECEIVE, });
export const createMappingFailure = error => ({
    type: types.CREATE_MAPPING_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const createMapping = (mapping) => (dispatch) => {
    dispatch(createMappingRequest());

    api.post(api.defaults.ADMIN_MAPPING, mapping)
        .then(() => {
            dispatch(createMappingReceive());
            dispatch(getMappings());
            navigate(`/admin/mappings`)
        })
        .catch(error => {
            dispatch(createMappingFailure(error.message));
        })
}

// Actions
export const deleteMappingRequest = () => ({ type: types.DELETE_MAPPING_REQUEST });
export const deleteMappingReceive = () => ({ type: types.DELETE_MAPPING_RECEIVE });
export const deleteMappingFailure = (error) => ({
    type: types.DELETE_MAPPING_FAILURE,
    payload: {
        error
    }
});

// Thunks
export const deleteMapping = (mapping) => (dispatch) => {
    dispatch(deleteMappingRequest());

    api.delete(`${api.defaults.ADMIN_MAPPING}`, {
        data: mapping
    })
        .then(() => {
            dispatch(deleteMappingReceive());
            dispatch(getMappings());
            navigate(`/admin/mappings`)
        })
        .catch(error => {
            dispatch(deleteMappingFailure(error.message));
        })
}