import React from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { confirm } from "utils/confirm";

// Actions
import * as reportActions from "actions/reports";

// Components
import { More } from "components/Table";

export default function Actions({
    id,
    systemGerenatedReportId,
    filename,
    filetype,
    type,
    period,
    isDeleting,
    hideVersionButton = false,
}) {
    const dispatch = useDispatch();

    const openFileHistory = () => {
        dispatch(reportActions.toggleFileHistory(type, period));
    };
    
    const downloadReport = () => {
        dispatch(reportActions.downloadReport(id));
    };

    const downloadGeneratedFile = () => {
        dispatch(reportActions.downloadReport(systemGerenatedReportId));
    };

    const generateReport = () => {
        dispatch(reportActions.generateReport(id, filetype));
    }

    const openDeleteReportConfirm = () => {
        confirm({
            title: "Are you sure?",
            description: `Are you sure you want to delete the following report: ${filename}?`,
        })
        .then((shouldProceed) => {
            if (shouldProceed) {
                let ids = [id];
                if(systemGerenatedReportId) {
                    ids.push(systemGerenatedReportId);
                }
                dispatch(reportActions.deleteReport(ids));
            }
        })
    }

    const buildVersionButton = () => {
        if (hideVersionButton) {
            return []
        }

        return [{
            type: "button",
            action: openFileHistory,
            text: `Version History`,
        }]
    }

    const buildGenerateReportButton = () => {
        if (systemGerenatedReportId) {
            return []
        }
        return [{
            type: "button",
            action: generateReport,
            text: filetype === 'xbrl'
                ? "Generate xlsx"
                : "Generate xbrl",
            icon: filetype === 'xbrl'
                ? (<i className="fa fa-file-excel-o"></i>)
                : (<i className="fa fa-file"></i>),
        }]
    }

    const buildDownloadGeneratedReportButton = () => {
        if (!systemGerenatedReportId) {
            return []
        }
        return [{
            type: "button",
            action: downloadGeneratedFile,
            text: filetype === 'xbrl'
                ? "Download xlsx"
                : "Download xbrl",
            icon: (<i className="fa fa-download"/>)
        }]
    }

    if (isDeleting) {
        return (
            <span style={ { fontSize: ".8rem" } }>Deleting</span>
        );
    }

    return (
        <More
            options={ [
                ...buildVersionButton(), 
                {
                    type: "button",
                    action: downloadReport,
                    text: `Download ${filetype}`,
                    icon: (<i className="fa fa-download"/>)
                } ,
                ...buildGenerateReportButton(),
                ...buildDownloadGeneratedReportButton(),
                {
                    type: "button",
                    action: openDeleteReportConfirm,
                    text: `Delete`,
                    icon: (<i className="fa fa-trash" />)
                }
            ] }
        />
    )
}
