import React from "react";
import classnames from "classnames";

import styles from "./styles.module.css";

export default function Button({
    className,
    onClick,
    children,
    variant = "primary",
    ...rest
}) {
    return (
        <button
            className={ classnames(styles.button, className, {
                [styles[variant]]: variant
            }) }
            onClick={ onClick }
            { ...rest }
        >
            { children }
        </button>
    )
}
