import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatDate, { DEFAULT_FORMAT } from "utils/formatDate";

// Actions
import { changeSelectedPeriod } from "actions/validations";

// Assets
import styles from "./styles.module.css";

export default function Filters() {
    const dispatch = useDispatch();

    const selectedPeriod = useSelector(state => state.validations.selectedPeriod);
    const periods = useSelector(state => state.validations.periods);

    const changePeriod = e => dispatch(changeSelectedPeriod(e.target.value));

    return (
        <>
            <select
                onChange={ changePeriod }
                value={ selectedPeriod }
                className={ styles.select }
            >
                {
                    periods.map(period => (
                        <option value={ period } key={ period }>
                            { formatDate(period, DEFAULT_FORMAT) }
                        </option>
                    ))
                }
            </select>
        </>
    )
}
