import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatDate, { DEFAULT_FORMAT } from 'utils/formatDate';

// Actions
import * as reportActions from "actions/reports";

// Components
import {
    Card,
    Modal,
    Table,
} from 'components';
import { CardHeader, CardContent, CardDescription, CardFooter } from "components/Card";

// Own Components
import MoreActions from "../MoreActions";

// Assets
import styles from "./styles.module.css";

function ModalVersions() {
    const dispatch = useDispatch();

    const idsBeingDeleted = useSelector(state => state.reports.catalog.idsBeingDeleted);

    const versionDetails = useSelector(state =>
        state.reports.catalog.versionDetails
    );
    const versions = versionDetails.versions;
    const versionTitle = `${versionDetails.type} Version History`
   
    const closeModal = () => {
        dispatch(reportActions.toggleFileHistory());
    };
    
    return (
        <Modal onClose={ closeModal }>
            <Card
                className={ styles.card }
                element="section"
            >
                <CardHeader
                    heading="h2"
                    title={versionTitle}
                    rightChildren={
                        <button
                            className={ styles.close }
                            onClick={ closeModal }
                            aria-label="Close Version History"
                        >
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    }
                />
                <CardContent>
                    <CardDescription>
                        {
                            formatDate(versionDetails?.period, DEFAULT_FORMAT)
                        }
                    </CardDescription>
                    <Table
                        minWidth={ 450 }
                        widths={ [200, 100, 100, 50] }
                        headItems={ ["File name", "Report type", "Version", ""] }
                        bodyItems={
                            versions.map(({ id, type, filename, content, version, period, filetype }) => ([
                                    filename,
                                    type,
                                    version,
                                    <MoreActions
                                        id={ id }
                                        filename={ filename }
                                        content={ content }
                                        filetype={ filetype }
                                        type={ type }
                                        period={ period }
                                        isDeleting={ idsBeingDeleted.includes(id) }
                                        hideVersionButton={ true }
                                    />
                                ])
                            )
                        }
                    />                    
                </CardContent>
                <CardFooter></CardFooter>
            </Card>
        </Modal>
    )
}

export default function Relay() {
    const versionDetails = useSelector(state =>
        state.reports.catalog.versionDetails
    );

    if (versionDetails.type && versionDetails.period) {
        return (
            <ModalVersions />
        )
    }

    return null;
}
