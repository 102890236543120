import { msalConfig, loginRequest, tokenRequest, silentRequest } from 'config/auth';
import api from 'config/api';
import { PublicClientApplication } from "@azure/msal-browser";
import { isIE } from "utils/helpers";

const msalApp = new PublicClientApplication(msalConfig);
const useRedirectFlow = isIE();

export const types = {
    LOGOUT_REQUEST: "auth/logoutRequest",
    LOGOUT_CONFIRM: "auth/logoutConfirm",
    LOGOUT_FAILURE: "auth/logoutFailure",

    SIGNIN_REQUEST: "auth/signinRequest",
    SIGNIN_CONFIRM: "auth/signinConfirm",
    SIGNIN_FAILURE: "auth/signinFailure",

    ACQUIRE_TOKEN_REQUEST: "auth/acquireTokenRequest",
    ACQUIRE_TOKEN_RECEIVE: "auth/acquireTokenReceive",
    ACQUIRE_TOKEN_FAILURE: "auth/acquireTokenFailure",

    CHANGE_CURRENT_STATUS: "auth/changeCurrentStatus",

    GET_USER_PERMISSIONS_REQUEST: "auth/getUserPermissionsRequest",
    GET_USER_PERMISSIONS_RECEIVE: "auth/getUserPermissionsReceive",
    GET_USER_PERMISSIONS_FAILURE: "auth/getUserPermissionsFailure",
}

export const changeCurrentStatus = status => ({
    type: types.CHANGE_CURRENT_STATUS,
    payload: {
        status
    }
});

export const logoutRequest = () => ({ type: types.LOGOUT_REQUEST, });
export const logoutConfirm = () => ({ type: types.LOGOUT_CONFIRM, });
export const logoutFailure = (error) => ({
    type: types.LOGOUT_FAILURE,
    payload: {
        error
    }
});

export const signinRequest = () => ({ type: types.SIGNIN_REQUEST, });
export const signinConfirm = data => ({
    type: types.SIGNIN_CONFIRM,
    payload: {
        ...data
    }
});
export const signinFailure = (error) => ({
    type: types.SIGNIN_FAILURE,
    payload: {
        error
    }
});

export const acquireTokenRequest = () => ({ type: types.ACQUIRE_TOKEN_REQUEST, });
export const acquireTokenReceive = data => ({
    type: types.ACQUIRE_TOKEN_RECEIVE,
    payload: {
        ...data
    }
});
export const acquireTokenFailure = error => ({
    type: types.ACQUIRE_TOKEN_FAILURE,
    payload: {
        error
    }
});

// Thunks
export const signIn = () => dispatch => {
    dispatch(signinRequest());

    if (useRedirectFlow) {
        return msalApp.loginRedirect(loginRequest);
    }

    return msalApp.loginPopup(loginRequest)
        .then(response => dispatch(signinConfirm(response)))
        .catch(() => dispatch(signinFailure("SIGN_IN_ERROR")));
}

export const logout = () => dispatch => {
    const username = sessionStorage.getItem('username');

    const request = {
        account: msalApp.getAccountByUsername(username)
    };

    dispatch(logoutRequest());
    return msalApp.logout(request)
        .then(() => dispatch(logoutConfirm()))
        .catch(() => dispatch(logoutFailure("LOG_OUT_ERROR")));
}

const msalAcquirePopupToken = async (username) => {
    try {
        tokenRequest.account = msalApp.getAccountByUsername(username);

        const data = await msalApp.acquireTokenPopup(tokenRequest)

        return data;
    } catch(e) {
        throw Error("ACQUIRE_TOKEN_ERROR");
    }
}
export const msalAcquireSilentToken = async (username) => {
    try {
        silentRequest.account = msalApp.getAccountByUsername(username);

        const data = await msalApp.acquireTokenSilent(silentRequest);

        return data;
    } catch(e) {
        return msalAcquirePopupToken()
    }
}

export const acquireToken = () => dispatch => {
    /**
     * See here for more info on account retrieval: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const username = sessionStorage.getItem('username');
    if (!username) {
        dispatch(changeCurrentStatus("EMPTY_AUTH"))
        return;
    }
     
    dispatch(acquireTokenRequest());

    return msalAcquireSilentToken(username)
        .then(data => dispatch(acquireTokenReceive(data)))
        .catch(e => dispatch(acquireTokenFailure(e.message)))
}


export const getUserPermissionsRequest = () => ({ type: types.GET_USER_PERMISSIONS_REQUEST, });
export const getUserPermissionsReceive = data => ({
    type: types.GET_USER_PERMISSIONS_RECEIVE,
    payload: {
        ...data
    }
});
export const getUserPermissionsFailure = error => ({
    type: types.GET_USER_PERMISSIONS_FAILURE,
    payload: {
        error
    }
});

// Thunks
export const getUserPermissions = () => (dispatch) => {
    dispatch(getUserPermissionsRequest());
    api.get(api.defaults.GET_USER_PERMISSIONS)
        .then((response) => {
            const permissions = response.data;
            dispatch(getUserPermissionsReceive(permissions));
        })
        .catch(error => {
            dispatch(getUserPermissionsFailure(error.message));
        })
}
