import api from 'config/api';

export const types = {
	GET_EMBED_CONFIG_REQUEST: "analytics/getEmbedConfigRequest",
	GET_EMBED_CONFIG_RECEIVE: "analytics/getEmbedConfigReceive",
	GET_EMBED_CONFIG_FAILURE: "analytics/getEmbedConfigFailure",
}

export const getEmbedConfigRequest = () => ({ type: types.GET_EMBED_CONFIG_REQUEST, });

export const getEmbedConfigReceive = embedConfig => ({
	type: types.GET_EMBED_CONFIG_RECEIVE,
	payload: {
		embedConfig,
	}
});

export const getEmbedConfigFailure = error => ({
	type: types.GET_EMBED_CONFIG_FAILURE,
	payload: {
		error,
	}
});

// Thunks
export const getEmbedConfig = () => (dispatch) => {
	dispatch(getEmbedConfigRequest());

	api.get(`${api.defaults.EMBED_POWER_BI}`)
		.then((response) => {
			const embedConfig = response.data;
			const embedConfigParsed = JSON.parse(embedConfig);

			dispatch(getEmbedConfigReceive(embedConfigParsed));
		})
		.catch(error => {
			dispatch(getEmbedConfigFailure(error.message));
		})
}