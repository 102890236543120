import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

export default function Header({
	title,
	heading,
	classes = {},
    beforeTitle,
	leftChildren,
    rightChildren,
}) {
	const Heading = heading || "h1";

	return (
		<header className={ classnames(styles.component, classes.root) }>
			<div className={ styles.left }>
                { beforeTitle }
				<Heading
					className={ classnames(styles.title, classes.title) }
				>
					{ title }
				</Heading>
				{ leftChildren }
			</div>
			<div className={ styles.right }>
				{ rightChildren }
			</div>
		</header>
	);
}

Header.propTypes = {
	title: PropTypes.string,
	heading: PropTypes.string,
	classes: PropTypes.shape({
		root: PropTypes.string,
		title: PropTypes.string,
	}),
	beforeTitle: PropTypes.node,
	leftChildren: PropTypes.node,
	rightChildren: PropTypes.node,
};
