import api from 'config/api';
import download from 'downloadjs';

export const types = {
    GET_RULES_REQUEST: "validations/getRulesRequest",
    GET_RULES_SUCCESS: "validations/getRulesSuccess",
    GET_RULES_FAILURE: "validations/getRulesFailure",

    VALIDATE_REQUEST: "validations/validateRequest",
    VALIDATE_RECEIVE: "validations/validateReceive",
    VALIDATE_FAILURE: "validations/validateFailure",

    GET_RESULTS_REQUEST: "validations/getResultsRequest",
    GET_RESULTS_RECEIVE: "validations/getResultsReceive",
    GET_RESULTS_FAILURE: "validations/getResultsFailure",

    CHANGE_SELECTED_PERIOD: "validations/changeSelectedPeriod",
    CHANGE_REPORT_SELECTED_PERIOD: "validations/changeReportSelectedPeriod",

    GET_RESULT_REQUEST: "validations/getResultRequest",
    GET_RESULT_RECEIVE: "validations/getResultReceive",
    GET_RESULT_FAILURE: "validations/getResultFailure",

    GET_REPORTS_REQUEST: "validations/getReportsRequest",
    GET_REPORTS_RECEIVE: "validations/getReportsReceive",
    GET_REPORTS_FAILURE: "validations/getReportsFailure",

    DOWNLOAD_REPORT_REQUEST: "validations/downloadReportRequest",
    DOWNLOAD_REPORT_SUCCEED: "validations/downloadReportSucceed",
    DOWNLOAD_REPORT_FAILURE: "validations/downloadReportFailure",

    CLOSE_RESULT_DETAILS_MODAL: "validations/closeResultDetailsModal",
}

export const closeResultDetailsModal = () => ({ type: types.CLOSE_RESULT_DETAILS_MODAL, });

export const changeSelectedPeriod = period => ({
    type: types.CHANGE_SELECTED_PERIOD,
    payload: {
        period
    }
});

export const changeReportSelectedPeriod = period => ({
    type: types.CHANGE_REPORT_SELECTED_PERIOD,
    payload: {
        period
    }
});

export const getRulesRequest = () => ({ type: types.GET_RULES_REQUEST, });

export const getRulesSuccess = (rules) => ({
    type: types.GET_RULES_SUCCESS,
    payload: {
        rules
    },
});

export const getRulesFailure = error => ({
    type: types.GET_RULES_FAILURE,
    payload: {
        error
    }
});

export const getRules = () => dispatch => {
    dispatch(getRulesRequest());
    
    api.get(`${api.defaults.GET_RULES}`)
        .then((res) => {
            dispatch(getRulesSuccess(res.data));
        })
        .catch(error => {
            dispatch(getRulesFailure(error.response));
        })
}


export const validateRequest = () => ({ type: types.VALIDATE_REQUEST, });
export const validateReceive = () => ({ type: types.VALIDATE_RECEIVE, });
export const validateFailure = error => ({
    type: types.VALIDATE_FAILURE,
    payload: {
        error,
    }
});

// Thunks

export const validate = (validate) => (dispatch) => {
    dispatch(validateRequest());

    api.post(`${api.defaults.VALIDATE}`, validate)
        .then((res) => {
            const actionId = res.data.actionId;
            if(actionId) {
                let actionIds = [];
                if(sessionStorage.getItem('validationActionIds')) {
                    actionIds = JSON.parse(sessionStorage.getItem('validationActionIds'));
                    actionIds.push(actionId);
                    sessionStorage.setItem("validationActionIds", JSON.stringify(actionIds));
                } else {
                    actionIds.push(actionId)
                    sessionStorage.setItem("validationActionIds", JSON.stringify(actionIds));
                }
            }
        })
        .catch(error => {
            dispatch(validateFailure(error.message));
        })
}


export const getResultsRequest = () => ({ type: types.GET_RESULTS_REQUEST, });

export const getResultsReceive = results => ({
    type: types.GET_RESULTS_RECEIVE,
    payload: {
        results,
    }
});

export const getResultsFailure = error => ({
    type: types.GET_RESULTS_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getResults = () => (dispatch) => {
    dispatch(getResultsRequest());

    api.get(`${api.defaults.GET_RESULTS}`)
        .then((response) => {
            const results = response.data;
            dispatch(getResultsReceive(results));
        })
        .catch(error => {
            dispatch(getResultsFailure(error.message));
        })
}


export const getResultRequest = () => ({ type: types.GET_RESULT_REQUEST, });

export const getResultReceive = result => ({
    type: types.GET_RESULT_RECEIVE,
    payload: {
        result,
    }
});

export const getResultFailure = error => ({
    type: types.GET_RESULT_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getResult = (result) => (dispatch) => {
    dispatch(getResultRequest());

    api.get(`${api.defaults.GET_RESULTS}/${result}`)
        .then((response) => {
            const result = response.data;
            dispatch(getResultReceive(result));
        })
        .catch(error => {
            dispatch(getResultFailure(error.message));
        })
}


export const getReportsRequest = () => ({ type: types.GET_REPORTS_REQUEST, });

export const getReportsReceive = reports => ({
    type: types.GET_REPORTS_RECEIVE,
    payload: {
        reports,
    }
});

export const getReportsFailure = error => ({
    type: types.GET_REPORTS_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getReports = () => (dispatch) => {
    dispatch(getReportsRequest());

    api.get(`${api.defaults.GET_VALIDATION_REPORTS}`)
        .then((response) => {
            const result = response.data;
            dispatch(getReportsReceive(result));
        })
        .catch(error => {
            dispatch(getReportsFailure(error.message));
        })
}


export const downloadReportRequest = id => ({
    type: types.DOWNLOAD_REPORT_REQUEST,
    payload: {
        id
    },
});
export const downloadReportSucceed = id => ({
    type: types.DOWNLOAD_REPORT_SUCCEED,
    payload: {
        id
    },
});
export const downloadReportFailure = (id, error) => ({
    type: types.DOWNLOAD_REPORT_FAILURE,
    payload: {
        id,
        error
    },
    error: true,
});
export const downloadReport = (id) => (dispatch) => {
    dispatch(downloadReportRequest());
    api.get(`${api.defaults.VALIDATION_REPORT_DOWNLOAD}/${id}`)
        .then((res) => {
            
            const { filename, filetype, content } = res.data;
            const data = `data:file/${filetype};base64,${content}`
            const mimeType = `file/${filetype}`;
            download(data, filename, mimeType);
            dispatch(downloadReportSucceed(id));
        })
        .catch(error => {
            dispatch(downloadReportFailure(id, error.response));
        })
}

export const checkValidationSubmissionStatus = (submissionId, submissionIds) => (dispatch) => {
    api.get(`${api.defaults.CHECK_VALIDATION_SUBMISSION_STATUS}/${submissionId}`)
        .then((res) => {
            if(res.data) {
                const validationSubmissionIds = submissionIds.filter(subId => subId !== submissionId)
                sessionStorage.setItem("validationActionIds", JSON.stringify(validationSubmissionIds));
                dispatch(getResults());
            }
        });
}