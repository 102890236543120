import { types } from "actions/validations";

const INITIAL_STATE = {
    isFetchingRules: false,
    isFetchingResults: false,
    isFetchingResult: false,
    isDownloading: false,
    isFetchingReports: false,
    rules: [],
    results: [],
    reports: [],
    result: undefined,
    list: [],
    selectedPeriod: undefined,
    periods: [],
    reportList: [],
    uniqueReports: [],
    uniqueResults: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_RULES_REQUEST:
            return {
                ...state,
                isFetchingRules: true,
            }            
        case types.GET_RULES_SUCCESS:
            return {
                ...state,
                isFetchingRules: false,
                rules: action.payload.rules
            }            
        case types.GET_RULES_FAILURE:
            return {
                ...state,
                isFetchingRules: false,
            }
        case types.GET_RESULTS_REQUEST:
            return {
                ...state,
                isFetchingResults: true,
                error: undefined,
            }
        case types.GET_RESULTS_RECEIVE:
            const results = action.payload.results;
            const uniqueResults = getUniqueResults(results)
            const periods = getResultPeriods(uniqueResults, state.selectedPeriod)
            const latestSelectedPeriod = state.selectedPeriod ? state.selectedPeriod : periods[0];
            return {
                ...state,
                isFetchingResults: false,
                periods: periods,
                results: results,
                uniqueResults: uniqueResults,
                list: filterResultsByPeriod(uniqueResults, latestSelectedPeriod),
            }
        case types.GET_RESULTS_FAILURE:
            return {
                ...state,
                isFetchingResults: false,
                error: action.payload.error,
            }
        case types.CHANGE_SELECTED_PERIOD:
            return {
                ...state,
                selectedPeriod: action.payload.period,
                list: filterResultsByPeriod(state.uniqueResults, action.payload.period)
            }
        case types.GET_RESULT_REQUEST:
            return {
                ...state,
                isFetchingResult: true,
                error: undefined,
            }
        case types.GET_RESULT_RECEIVE:
            return {
                ...state,
                isFetchingResult: false,
                result: action.payload.result
            }
        case types.GET_RESULT_FAILURE:
            return {
                ...state,
                isFetchingResult: false,
                error: action.payload.error,
        }
        case types.CLOSE_RESULT_DETAILS_MODAL:
            return {
                ...state,
                result: undefined
            }
        case types.DOWNLOAD_REPORT_REQUEST:
            return {
                ...state,
                isDownloading: true,
            }
        case types.DOWNLOAD_REPORT_SUCCEED:
            return {
                ...state,
                isDownloading: false,
            }
        case types.DOWNLOAD_REPORT_FAILURE:
            return {
                ...state,
                isDownloading: false,
                error: action.payload.error
            }
        case types.GET_REPORTS_REQUEST:
            return {
                ...state,
                isFetchingReports: true,
            }
        case types.CHANGE_REPORT_SELECTED_PERIOD:
            return {
                ...state,
                reportSelectedPeriod: action.payload.period,
                reportList: filterReportsByPeriod(state.uniqueReports, action.payload.period)
            }
        case types.GET_REPORTS_RECEIVE:
            const reports = action.payload.reports;
            const uniqueReports = getUniqueReports(reports)
            const reportPeriods = getReportPeriods(uniqueReports, state.reportSelectedPeriod)
            const latestReportSelectedPeriod = state.reportSelectedPeriod ? state.reportSelectedPeriod : reportPeriods[0];
            return {
                ...state,
                isFetchingReports: false,
                reports: reports,
                uniqueReports: uniqueReports,
                reportList: filterReportsByPeriod(uniqueReports, latestReportSelectedPeriod),
                reportPeriods: reportPeriods,
            }
        case types.GET_REPORTS_FAILURE:
            return {
                ...state,
                isFetchingReports: false,
            }
        default:
            return state;
    }
}

const filterResultsByPeriod = (results, period) => {
    if (!period) {
        return results;
    }
    return results.filter(result => result.reportPeriod === period)
}

const filterReportsByPeriod = (reports, period) => {
    if (!period) {
        return reports;
    }
    return reports.filter(report => report.period === period)
}

const getReportPeriods = (reports, selectedPeriod) => {
    const period = [];
    if(selectedPeriod){
        period.push(selectedPeriod)
    }

    return reports.reduce((acc, report) => {
        if (acc.includes(report.period)) {
            return acc;
        }
        if (!report.period) {
            return acc;
        }

        return [
            ...acc,
            report.period,
        ]
    }, period).reverse()
}

const getResultPeriods = (results, selectedPeriod) => {
    const period = [];
    if(selectedPeriod){
        period.push(selectedPeriod)
    }

    return results.reduce((acc, result) => {
        if (acc.includes(result.reportPeriod)) {
            return acc;
        }
        if (!result.reportPeriod) {
            return acc;
        }

        return [
            ...acc,
            result.reportPeriod,
        ]
    }, period).reverse()
}

const getUniqueReports = (reports) => {
    let finalReports = {};
    reports.map(report => {
		let str = report.type + report.period.replace(":", "");
		finalReports[str] = report
    })
    const results = Object.values(finalReports)
    return results
}

const getUniqueResults = (results) => {
    let finalResults = {};
    results.map(result => {
		let str = result.type + result.reportPeriod.replace(":", "");
		finalResults[str] = result
    })
    return Object.values(finalResults)
}