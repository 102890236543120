import { types } from "actions/admin/entities";

const INITIAL_STATE = {
    isFetching: false,
    isCreating: false,
    entities: [],
    entity: {},    
    idsBeingDeleted: [],
    error: undefined,
    errorSelecting: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_ENTITIES_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_ENTITIES_RECEIVE:
            return {
                ...state,
                isFetching: false,
                entity: {},
                entities: action.payload.entities
            }
        case types.GET_ENTITIES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            }
        case types.CREATE_ENTITY_REQUEST:
            return {
                ...state,
                isCreating: true,
                error: undefined,
            }
        case types.CREATE_ENTITY_RECEIVE:
            return {
                ...state,
                isCreating: false
            }
        case types.CREATE_ENTITY_FAILURE:
            return {
                ...state,
                isCreating: false,
                error: action.payload.error,
            }
        case types.DELETE_ENTITY_REQUEST:
            return {
                ...state,
                idsBeingDeleted: [...state.idsBeingDeleted, action.payload.id],
                error: undefined,
            }
        case types.DELETE_ENTITY_RECEIVE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id)
            }
        case types.DELETE_ENTITY_FAILURE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id),
                error: action.payload.error,
            }
        case types.EDIT_ENTITY_REQUEST:
            return {
                ...state,
                isEditing: true,
                error: undefined,
            }
        case types.EDIT_ENTITY_RECEIVE:
            return {
                ...state,
                isEditing: false,
            }
        case types.EDIT_ENTITY_FAILURE:
            return {
                ...state,
                isEditing: false,
                error: action.payload.error,
            }
        case types.GET_ENTITY_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_ENTITY_RECEIVE:
            return {
                ...state,
                isFetching: false,
                entity: action.payload.entity
            }
        case types.GET_ENTITY_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
        }
        default:
            return state
    }
}
