import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "@reach/router";
import {  DropdownButton, Dropdown } from 'react-bootstrap';

import * as actionsAuth from "actions/auth";

// Assets
import 'font-awesome/css/font-awesome.min.css';
import styles from "./styles.module.css";
import logo from 'assets/imgs/ey-logo.svg';

const NavigationAdmin = () => {
    return (
        <DropdownButton title="Admin" className={ styles.dropdown }>
            <Dropdown.Item>
                <Link to="/admin/tenants">Tenants</Link>
            </Dropdown.Item>
            <Dropdown.Item>
                <Link to="/admin/users">Users</Link>
            </Dropdown.Item>
            <Dropdown.Item>
                <Link to="/admin/mappings">User Tenant Mappings</Link>
            </Dropdown.Item>
            <Dropdown.Item>
            <Link to="/admin/powerbis">Power BI</Link>
            </Dropdown.Item>
        </DropdownButton>
    )
}

export default function Navbar() { 
    const dispatch = useDispatch();

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const selectedTenant = useSelector(state => state.tenants.selected);
    const selectedEntityName = useSelector(state => state.entities.selected.name);
    const name = useSelector(state => state.auth.account.name);
    const { userType } = useSelector(state => state.auth);

    const signout = () => {
        dispatch(actionsAuth.logout())
    }

    return (
        <div className={ styles.component }>
            <div className={ styles.left }>
                <Link to="/">
                    <img src={ logo } className={ styles.logo } alt="logo" />
                </Link>
                <span className={ styles.brand }>RegReportingHub { process.env.REACT_APP_STAGE !== "prod" && process.env.REACT_APP_STAGE }
                {
                    selectedTenant.id && (
                        ` // ${selectedTenant.name}`
                    )
                }
                {
                    selectedEntityName && (
                        ` // ${selectedEntityName}`
                    )
                }            
                </span>
            </div>
            <div className={ styles.right }>
                {
                    isAuthenticated && (
                        <div>
                            {
                                (userType === 'admin' || userType === 'all') && (
                                    <NavigationAdmin />
                                )
                            }
                            <span className={ styles.item }>{ name }</span>
                            <button
                                className={ styles.button }
                                onClick={ signout }
                            >
                                <i className="fa fa-power-off" aria-hidden="true"></i>
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

// Component.propTypes = {
//     account: PropTypes.object,
//     error: PropTypes.string,
//     signIn: PropTypes.func.isRequired,
//     signOut: PropTypes.func.isRequired,
//     acquireToken: PropTypes.func.isRequired,
//   }
