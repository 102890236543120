// For a full list of msal.js configuration parameters, 
// visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export const msalConfig = {
    //auth: {
      //   clientId: "f8767c09-2ba3-428a-bad0-703b49d1291c",
        // authority: "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c",
        // redirectUri: "http://localhost:3000"
         //redirectUri: "http://rrh.ey.com"
     //},

    auth: {
         clientId: process.env.REACT_APP_CLIENTID,  //"a3d23162-35df-42a1-bfb0-47e54d3c0d60",
         authority: process.env.REACT_APP_AUTH_AUTHORITY, //"https://login.microsoftonline.com/82555573-663e-4b63-a6ae-a96d2a8e9c79",
         validateAuthority: true, 
         redirectUri: process.env.REACT_APP_REDIRECTURI, // "http://localhost:3000/",
         navigateToLoginRequestUrl: false
     },

    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
}

// Coordinates and required scopes for your web api
export const apiConfig = {
    //resourceUri: "https://localhost:5001/api/profile",
    //resourceScope: "api://064f78ae-d0a1-4854-aa4f-d3924d75e5ff/user_impersonation"
    resourceUri: process.env.REACT_APP_API_PROFILE, 
    resourceScope: process.env.REACT_APP_API_RESOURCESCOPE
}

/** 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const loginRequest = {
    scopes: ["openid", "profile", "offline_access", apiConfig.resourceScope]
}

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
    scopes: [apiConfig.resourceScope]
}

// Add here scopes for silent token request
export const silentRequest = {
    scopes: ["openid", "profile", apiConfig.resourceScope]
}
