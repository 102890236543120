import React from 'react';

// Own Components
import { Header, ModalUpload, AllFiles, ModalDownloadTemplate } from "./components";

// Assets
import styles from "./styles.module.css";

/**
 * Requirements:
 * - Reports should come order by period DESC
 */
export default function Reports() {
    return (
        <main className={ styles.view }>
            <Header />
            <AllFiles />
            <ModalUpload />
            <ModalDownloadTemplate />
        </main>
    )
}
