import React from 'react';
import { navigate } from "@reach/router";
import { useDispatch } from 'react-redux';

// Utils
import { confirm } from "utils/confirm";

// Actions
import * as actionsUsers from "actions/admin/users";

// Components
import { More } from "components/Table";

export default function Actions({
    objectId,
    email,
    isDeleting
}) {
    const dispatch = useDispatch();

    const viewUser = () => {
        navigate(`users/view/${objectId}`)
    }

    const editUser = () => {
        navigate(`users/edit/${objectId}`)     
    }    

    const deleteUser = () => {
        confirm({
            title: "Are you sure?",
            description: `Are you sure you want to delete the following user: ${email}?`,
        })
        .then((shouldProceed) => {
            if (shouldProceed) {
                dispatch(actionsUsers.deleteUser(objectId));
            }
        })
    }

    if (isDeleting) {
        return (
            <span style={ { fontSize: ".8rem" } }>Deleting</span>
        );
    }

    return (
        <More
            options={ [
                {
                    type: "button",
                    action: viewUser,
                    text: "View",
                    icon: (<i className="fa fa-info-circle"/>)
                } ,
                {
                    type: "button",
                    action: editUser,
                    text: "Edit",
                    icon: (<i className="fa fa-edit"/>)
                } ,
                {
                    type: "button",
                    action: deleteUser,
                    text: `Delete`,
                    icon: (<i className="fa fa-trash" />)
                }
            ] }
        />
    )
}
