import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Components
import { Header, Button, Table } from "components";

// Utils
import { confirm } from "utils/confirm";

// Assets
import styles from "./styles.module.css";

// Actions
import * as actionsMappings from "actions/admin/mappings";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function Mapping() {

    const dispatch = useDispatch();
    
    const {
        isFetching,
        mappings
    } = useSelector(state => state.admin.mappings);    
    
    const deleteMapping = (tenantId, userObjectId) => {
        const mapping = {
            userObjectId,
            tenantId: parseInt(tenantId)
        }

        confirm({
            title: "Are you sure?",
            description: `Are you sure you want to delete this mapping?`,
        })
        .then((shouldProceed) => {
            if (shouldProceed) {
                dispatch(actionsMappings.deleteMapping(mapping));
            }
        })
    }

    useEffect(() => {
        dispatch(actionsMappings.getMappings());
    }, [])

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="User Tenant Mappings"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/mappings/create') } }
                        className={ styles.button }
                    >
                        Create a new mapping
                    </Button>                  
                }
            />

            <Table
                emptyMessage="No mappings found"
                isLoadingData={ isFetching }
                widths={ [200, 300, 300] }
                headItems={ ["Tenant", "User", "Action"] }
                bodyItems={
                    mappings.map(({
                        tenant,
                        user
                    }) => ([
                        tenant.name,
                        user.email,
                        <i className="fa fa-trash" onClick={() => { deleteMapping(tenant.id, user.objectId) }} />
                    ]))
                }
            />
        </main>
    )
}