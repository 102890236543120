import React from "react";

import styles from "./styles.module.css";

export { default as More } from "./More";

export default function Table({
    isLoadingData = false,
    minWidth,
    widths = [],
    headItems = [],
    bodyItems = [],
    emptyMessage = "",
}) {
    return (
        <div className={ styles.container }>
            {
                isLoadingData && (
                    <div className={ styles.overlay } />
                )
            }
            <div className={ styles.table }>
                <div
                    className={ styles.head }
                    style={{ minWidth }}
                >
                    {
                        headItems.map((item, i) => (
                            <span
                                key={ i }
                                className={ styles.item }
                                style={ {
                                    width: widths[i],
                                    minWidth: widths[i]
                                } }
                            >
                                { item }
                            </span>
                        ))
                    }
                </div>
                <div className={ styles.content }>
                    {
                        !isLoadingData &&
                        bodyItems.length === 0 && (
                            <div
                                className={ styles.emptyMessage }
                            >
                                { emptyMessage }
                            </div>
                        )
                    }
                    {
                        bodyItems.map((items, j) => (
                            <div key={ j } className={ styles.row }>
                                {
                                    items.map((item, i) => (
                                        <span
                                            key={ i }
                                            className={ styles.item }
                                            style={ {
                                                width: widths[i],
                                                minWidth: widths[i]
                                            } }                            
                                        >
                                            { item }
                                        </span>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )    
}