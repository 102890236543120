import React, { useEffect } from 'react';

// Actions
import * as reportActions from "actions/reports";
import * as templatesActions from "actions/templates";

// Components
import {
    Header,
    Button,
} from "components";

// Assets
import styles from "./styles.module.css";
import { useDispatch } from 'react-redux';

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function HeaderContainer() {
    const dispatch = useDispatch();

    const openUploadModal = () => dispatch(reportActions.openUploadModal());
    const openDownloadTemplateModal = () => {
        dispatch(templatesActions.openDownloadTemplateModal());
    }

    useEffect(() => {
        return () => {
            dispatch(reportActions.closeUploadModal());
            dispatch(templatesActions.closeDownloadTemplateModal());
        }
    }, []);

    return (
        <Header
            heading="h1"
            title="Reports"
            classes={ HEADER_CLASSES }
            leftChildren={
                <Button onClick={ openUploadModal }>
                    Upload Report
                </Button>
            }
            rightChildren={
                <Button onClick={ openDownloadTemplateModal }>
                    Download Templates
                </Button>
            }
        />
    )
}
