import api from 'config/api';
import { navigate } from "@reach/router"

// Types
export const types = {
    GET_POWERBIS_REQUEST: "powerbi/getPowerbisRequest",
    GET_POWERBIS_RECEIVE: "powerbi/getPowerbisReceive",
    GET_POWERBIS_FAILURE: "powerbi/getPowerbisFailure",

    GET_POWERBI_REQUEST: "powerbi/getPowerbiRequest",
    GET_POWERBI_RECEIVE: "powerbi/getPowerbiReceive",
    GET_POWERBI_FAILURE: "powerbi/getPowerbiFailure",

    CREATE_POWERBI_REQUEST: "powerbi/createPowerbiRequest",
    CREATE_POWERBI_RECEIVE: "powerbi/createPowerbiReceive",
    CREATE_POWERBI_FAILURE: "powerbi/createPowerbiFailure",

    DELETE_POWERBI_REQUEST: "powerbi/deletePowerbiRequest",
    DELETE_POWERBI_RECEIVE: "powerbi/deletePowerbiReceive",
    DELETE_POWERBI_FAILURE: "powerbi/deletePowerbiFailure",

    EDIT_POWERBI_REQUEST: "powerbi/editPowerbiRequest",
    EDIT_POWERBI_RECEIVE: "powerbi/editPowerbiReceive",
    EDIT_POWERBI_FAILURE: "powerbi/editPowerbiFailure",
}

// Actions
export const getPowerbisRequest = () => ({ type: types.GET_POWERBIS_REQUEST, });

export const getPowerbisReceive = powerbi => ({
    type: types.GET_POWERBIS_RECEIVE,
    payload: {
        powerbi,
    }
});

export const getPowerbisFailure = error => ({
    type: types.GET_POWERBIS_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getPowerbis = () => (dispatch) => {
    dispatch(getPowerbisRequest());

    api.get(api.defaults.ADMIN_POWER_BI)
        .then((response) => {
            const powerbi = response.data;
            dispatch(getPowerbisReceive(powerbi));
        })
        .catch(error => {
            dispatch(getPowerbisFailure(error.message));
        })
}


export const createPowerbiRequest = () => ({ type: types.CREATE_POWERBI_REQUEST, });
export const createPowerbiReceive = () => ({ type: types.CREATE_POWERBI_RECEIVE, });
export const createPowerbiFailure = error => ({
    type: types.CREATE_POWERBI_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const createPowerbi = (powerbi) => (dispatch) => {
    dispatch(createPowerbiRequest());

    api.post(api.defaults.ADMIN_POWER_BI, powerbi)
        .then(() => {
            dispatch(createPowerbiReceive());
            dispatch(getPowerbis());
            navigate(`/admin/powerbis`)
        })
        .catch(error => {
            dispatch(createPowerbiFailure(error.message));
        })
}


export const deletePowerbiRequest = (id) => ({ 
    type: types.DELETE_POWERBI_REQUEST,
    payload: {
        id
    }
});
export const deletePowerbiReceive = (id) => ({ 
    type: types.DELETE_POWERBI_RECEIVE, 
    payload: {
        id
    }
});
export const deletePowerbiFailure = (error, id) => ({
    type: types.DELETE_POWERBI_FAILURE,
    payload: {
        error,
        id
    }
});

// Thunks
export const deletePowerbi = (id) => (dispatch) => {
    dispatch(deletePowerbiRequest(id));

    api.delete(`${api.defaults.ADMIN_POWER_BI}/${id}`)
        .then(() => {
            dispatch(deletePowerbiReceive(id));
            dispatch(getPowerbis());
            navigate(`/admin/powerbis`)
        })
        .catch(error => {
            dispatch(deletePowerbiFailure(error.message, id));
        })
}


export const editPowerbiRequest = () => ({ type: types.EDIT_POWERBI_REQUEST, });
export const editPowerbiReceive = (powerbi) => ({ 
    type: types.EDIT_POWERBI_RECEIVE,
    payload: {
        powerbi,
    }
});
export const editPowerbiFailure = error => ({
    type: types.EDIT_POWERBI_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const editPowerbi = (powerbi) => (dispatch) => {
    dispatch(editPowerbiRequest());

    api.put(`${api.defaults.ADMIN_POWER_BI}`, powerbi)
        .then((response) => {
            const powerbi = response.data;
            dispatch(editPowerbiReceive(powerbi));
            dispatch(getPowerbis());
            navigate(`/admin/powerbis`)
        })
        .catch(error => {
            dispatch(editPowerbiFailure(error.message));
        })
}


export const getPowerbiRequest = () => ({ type: types.GET_POWERBI_REQUEST, });

export const getPowerbiReceive = powerbi => ({
    type: types.GET_POWERBI_RECEIVE,
    payload: {
        powerbi,
    }
});

export const getPowerbiFailure = error => ({
    type: types.GET_POWERBI_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getPowerbi = (powerbi) => (dispatch) => {
    dispatch(getPowerbiRequest());

    api.get(`${api.defaults.ADMIN_POWER_BI}/${powerbi}`)
        .then((response) => {
            const powerbi = response.data;
            dispatch(getPowerbiReceive(powerbi));
        })
        .catch(error => {
            dispatch(getPowerbiFailure(error.message));
        })
}
