import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button,
    Header,
} from 'components';

import { Row, Col } from 'react-bootstrap';

// Actions
import * as corefilingsActions from "actions/corefilings";

import styles from "./styles.module.css";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function Corefilings() {
    const dispatch = useDispatch();

    const [pipeline, setPipeline] = useState('')
    const [processor, setProcessor] = useState('')
    const [schemaRef, setSchemaRef] = useState('')
    const { pipelines, processors } = useSelector(state => state.corefilings);

    const changeProcessor = (e) => {
        const processor = e.target.value;
        setProcessor(processor);
        setPipeline('');
        setSchemaRef('');
        dispatch(corefilingsActions.getPipelines(processor));
    }
    const changePipeline = (e) => {
        const pipeline = e.target.value;
        setPipeline(pipeline)
        setSchemaRef('');
        dispatch(corefilingsActions.getSchemaRefsFromPipeline(pipeline));
    }

    const generateTemplates = () => {
        dispatch(corefilingsActions.generateTemplate(pipeline, processor));
    }

    const populatePipeline = () => {
        dispatch(corefilingsActions.populatePipeline(processor));
    }

    const populateDatapoint = () => {
        dispatch(corefilingsActions.populateDatapoint(pipeline, processor));
    }

    useEffect(() => {        
        dispatch(corefilingsActions.getProcessors());
    }, [])

    return (
        <div className={ styles.view }>
            <Header
                heading="h1"
                title="Corefiling"
                classes={ HEADER_CLASSES }
            />
            <Row>
                <Col xs lg={2}>
                    <select
                        onChange={ changeProcessor }
                        className={ styles.select }
                    >
                        <option>
                            Select a Processor
                        </option>
                        {
                            processors.map(({ name }) => (
                                <option value={ name } key={ name }>
                                    { name }
                                </option>
                            ))
                        }
                    </select>                    
                </Col>
                <Col xs lg={2}>
                    <select
                        onChange={ changePipeline }
                        className={ styles.select }
                    >
                        <option>
                            Select a pipeline
                        </option>
                        {
                            pipelines.map(({ name }) => (
                                <option value={ name } key={ name }>
                                    { name }
                                </option>
                            ))
                        }
                    </select>
                </Col>
                <Col xs lg={2}>
                    <Button
                        className={ styles.button }
                        disabled={ !processor || !pipeline }
                        onClick={ generateTemplates }
                    >
                        Generate Templates
                    </Button>
                </Col>
                <Col xs lg={2}>
                    <Button
                        className={ styles.button }
                        disabled={ !processor }
                        onClick={ populatePipeline }
                    >
                        Populate Pipelines
                    </Button>
                </Col>
                <Col xs lg={2}>
                    <Button
                        className={ styles.button }
                        disabled={ !pipeline }
                        onClick={ populateDatapoint }
                    >
                        Populate Data Points
                    </Button>
                </Col>
            </Row>
        </div>
    )
}
