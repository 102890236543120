import React from 'react';
import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import * as actionsLogs from "actions/logs";

export default function LogPaginate() {
    const dispatch = useDispatch();
    const { pageCount, pageNumber } = useSelector(state => state.logs);

    const getActionsInCurrentPage = (pageNumber) => {
        dispatch(actionsLogs.getActionsInCurrentPage(pageNumber))
    }

    return (
        pageCount > 1 && <Pagination size="lg" className="paginate-bar">
            <Pagination.First
                onClick={() => getActionsInCurrentPage(1)}
                disabled={!pageCount}
                />
            <Pagination.Prev 
                onClick={() => getActionsInCurrentPage(pageNumber - 1)}
                disabled={pageNumber == 1}
                />
            <Pagination.Next 
                onClick={() => getActionsInCurrentPage(pageNumber + 1)} 
                disabled={pageNumber == pageCount}
                />
            <Pagination.Last 
                onClick={() => getActionsInCurrentPage(pageCount)}
                disabled={pageNumber == pageCount}
                />
        </Pagination>
    )
}