import { types } from "actions/analytics";

const INITIAL_STATE = {
	item: {},
	error: undefined,
	isFetching: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_EMBED_CONFIG_REQUEST:
			return {
				...state,
				isFetching: true,
				error: undefined,
			};
		case types.GET_EMBED_CONFIG_RECEIVE:
			return {
				...state,
				isFetching: false,
				item: action.payload.embedConfig,
			};
		case types.GET_EMBED_CONFIG_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload
			};
		default:
			return state;
	}
}
