import { types } from "actions/notifications";

const INITIAL_STATE = {
    isFetchingNotifications: false,
    notifications: [],
    error: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                isFetchingNotifications: true,
                error: undefined,
            }
        case types.GET_NOTIFICATIONS_RECEIVE:
            return {
                ...state,
                isFetchingNotifications: false,
                notifications: filterNotifications(action.payload.notifications)
            }
        case types.GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                isFetchingNotifications: false,
                error: action.payload.error,
            }
        default:
            return state;
    }
}

const filterNotifications = (notifications) => {
    return notifications.filter(action => action.uI_Noticed === false && action.finished === true).map(notification => {
        return {
            id: notification.id,
            description: notification.type,
            successStatus: notification.withErrors ? "error_toastr" : "success_toastr",
            message: notification.withErrors ? `The action ${notification.type} is completed with errors` : `The action ${notification.type} is completed successfully`
        }
    })
}