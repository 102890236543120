import { apiConfig } from 'config/auth';

export const types = {
    GET_PROFILE_REQUEST: "profile/getProfileRequest",
    GET_PROFILE_RECEIVE: "profile/getProfileReceive",
    GET_PROFILE_FAILURE: "profile/getProfileFailure",
}

export const getProfileRequest = () => ({ type: types.GET_PROFILE_REQUEST, });

export const getProfileReceive = profile => ({
    type: types.GET_PROFILE_RECEIVE,
    payload: {
        profile,
    }
});

export const getProfileFailure = error => ({
    type: types.GET_PROFILE_FAILURE,
    payload: {
        error,
    }
});


export const getProfile = (id) => (dispatch, getState) => {
    dispatch(getProfileRequest());

    return fetch(apiConfig.resourceUri + '/' + id, {
        method: 'GET',
        headers: {
            "Authorization": `Bearer ${getState().auth.accessToken}`,
            "Content-Type": 'application/json'
        }

        
    }).then(response => {
        if (response && response.status !== 404) {
            return response.json();
        }
    })
    .then((response) => {
        if (response) {
            dispatch(getProfileReceive(response));
        }
    })
    .catch(err => {
        console.log(err)
        dispatch(getProfileFailure(err.message));
    });
};

export const postProfile = (profile) => (dispatch, getState) => {
    var toek = getState().auth.accessToken;
    console.log('token ' + toek);
    return fetch(apiConfig.resourceUri, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${getState().auth.accessToken}`,
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(profile)
    })
    .then(response => {
        if (response && response.status !== 404) {
            return response.json();
        }
    })
    .then((response) => {
        if (response === 'interaction required') {
            // trigger interaction
        } else {
            dispatch(getProfileReceive(response))
        }
    })
    .catch(err => console.log(err))
};

export const putProfile = (profile) => (dispatch, getState) => {
    return fetch(apiConfig.resourceUri + '/' + profile.id, {
        method: 'PUT',
        headers: {
            "Authorization": `Bearer ${getState().auth.accessToken}`,
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(profile)
    })
    .then(() => dispatch(getProfileReceive(profile)))
    .catch(err => console.log(err));
};
