import { types } from "actions/admin/powerbis";

const INITIAL_STATE = {
    isFetching: false,
    isCreating: false,
    powerbis: [],
    powerbi: {},
    idsBeingDeleted: [],
    error: undefined,
    errorSelecting: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_POWERBIS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_POWERBIS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                powerbi: {},
                powerbis: action.payload.powerbi
            }
        case types.GET_POWERBIS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            }
        case types.CREATE_POWERBI_REQUEST:
            return {
                ...state,
                isCreating: true,
                error: undefined,
            }
        case types.CREATE_POWERBI_RECEIVE:
            return {
                ...state,
                isCreating: false
            }
        case types.CREATE_POWERBI_FAILURE:
            return {
                ...state,
                isCreating: false,
                error: action.payload.error,
            }
        case types.DELETE_POWERBI_REQUEST:
            return {
                ...state,
                idsBeingDeleted: [...state.idsBeingDeleted, action.payload.id],
                error: undefined,
            }
        case types.DELETE_POWERBI_RECEIVE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id)
            }
        case types.DELETE_POWERBI_FAILURE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id),
                error: action.payload.error,
            }
        case types.EDIT_POWERBI_REQUEST:
            return {
                ...state,
                isEditing: true,
                error: undefined,
            }
        case types.EDIT_POWERBI_RECEIVE:
            return {
                ...state,
                isEditing: false,
            }
        case types.EDIT_POWERBI_FAILURE:
            return {
                ...state,
                isEditing: false,
                error: action.payload.error,
            }
        case types.GET_POWERBI_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_POWERBI_RECEIVE:
            return {
                ...state,
                isFetching: false,
                powerbi: action.payload.powerbi
            }
        case types.GET_POWERBI_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
        }
        default:
            return state
    }
}
