import api from 'config/api';

// Actions
import * as actionsEntities from "actions/entities";

// Types
export const types = {
    GET_LIST_REQUEST: "tenants/getListRequest",
    GET_LIST_RECEIVE: "tenants/getListReceive",
    GET_LIST_FAILURE: "tenants/getListFailure",
    
    SELECT_TENANT_REQUEST: "tenants/selectTenantRequest",
    SELECT_TENANT_CONFIRM: "tenants/selectTenantConfirm",
    SELECT_TENANT_FAILURE: "tenants/selectTenantFailure",
}

// Actions
export const selectTenantRequest = () => ({ type: types.SELECT_TENANT_REQUEST, });

export const selectTenantConfirm = tenant => ({
    type: types.SELECT_TENANT_CONFIRM,
    payload: {
        tenant,
    }
});

export const selectTenantFailure = error => ({
    type: types.SELECT_TENANT_FAILURE,
    payload: {
        error,
    }
});

export const getListRequest = () => ({ type: types.GET_LIST_REQUEST, });

export const getListReceive = list => ({
    type: types.GET_LIST_RECEIVE,
    payload: {
        list,
    }
});

export const getListFailure = error => ({
    type: types.GET_LIST_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const selectTenant = (tenant) => (dispatch) => {
    if (!tenant) {
        return;
    }

    dispatch(selectTenantRequest());

    return api.get(api.defaults.SET_TENANTS + "/" + tenant.id)
        .then(() => {
            dispatch(selectTenantConfirm(tenant));
            dispatch(actionsEntities.getEntities(tenant));
        })
        .catch(error => {
            dispatch(selectTenantFailure(error.message));
        })
}

export const getTenants = () => (dispatch) => {
    dispatch(getListRequest());

    api.get(api.defaults.GET_TENANTS)
        .then((response) => {
            const tenants = response.data;
            dispatch(getListReceive(tenants));
        })
        .catch(error => {
            dispatch(getListFailure(error.message));
        })
}
