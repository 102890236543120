import { types } from "actions/admin/mappings"

const INITIAL_STATE = {
    isFetching: false,
    isCreating: false,
    mappings: [],
    mapping: {},
    error: undefined,
    errorSelecting: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_MAPPINGS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_MAPPINGS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                mapping: {},
                mappings: action.payload.mappings
            }
        case types.GET_MAPPINGS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            }
        case types.CREATE_MAPPING_REQUEST:
            return {
                ...state,
                isCreating: true,
                error: undefined,
            }
        case types.CREATE_MAPPING_RECEIVE:
            return {
                ...state,
                isCreating: false
            }
        case types.CREATE_MAPPING_FAILURE:
            return {
                ...state,
                isCreating: false,
                error: action.payload.error,
            }
        case types.DELETE_MAPPING_REQUEST:
            return {
                ...state,
                idsBeingDeleted: true,
                error: undefined,
            }
        case types.DELETE_MAPPING_RECEIVE:
            return {
                ...state,
                idsBeingDeleted: false
            }
        case types.DELETE_MAPPING_FAILURE:
            return {
                ...state,
                idsBeingDeleted: false,
                error: action.payload.error,
            }
        default:
            return state
    }
}
