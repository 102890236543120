import { types } from "actions/logs";
const INITIAL_STATE = {
    isFetchingActions: false,
    actions: [],
    uniqueActionTypes: [],
    error: undefined,
    list: [],
    isFetchingLogs: false,
    actionsInCategory: [],
    pageCount: 0,
    pageNumber: 1,
    pageSize: 12,
    currentPageActions: [],
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_ACTIONS_REQUEST:
            return {
                ...state,
                isFetchingActions: true,
                error: undefined,
            }
        case types.GET_ACTIONS_SUCCESS:
            return {
                ...state,
                isFetchingActions: false,
                uniqueActionTypes: getUniqueActionType(action.payload.actions),
                actions: action.payload.actions,
                pageNumber: 1,
            }
        case types.GET_ACTIONS_FAILURE:
            return {
                ...state,
                isFetchingActions: false,
                error: action.payload.error,
            }
        case types.GET_LOGS_REQUEST:
            return {
                ...state,
                isFetchingLogs: true,
                error: undefined,
            }
        case types.GET_LOGS_SUCCESS:
            return {
                ...state,
                isFetchingLogs: false,
                list: action.payload.logs,
            }
        case types.GET_LOGS_FAILURE:
            return {
                ...state,
                isFetchingLogs: false,
                error: action.payload.error,
            }
        case types.GET_ACTIONS_IN_CATEGORY:
            const actionsInCategory = getActionsByCategory(state.actions, action.payload.category)
            return {
                ...state,
                list:  [],
                pageCount: getPageCount(actionsInCategory, state.pageSize),
                actionsInCategory: actionsInCategory,
                currentPageActions: getCurrentActions(actionsInCategory, state.pageNumber, state.pageSize)
            }
        case types.GET_CURRENT_ACTIONS:
            return {
                ...state,
                pageNumber: action.payload.pageNumber,
                currentPageActions: getCurrentActions(state.actionsInCategory, action.payload.pageNumber, state.pageSize)
            }
        default:
            return state
    }
}
const getUniqueActionType = (actions) => {
    return actions.reduce((acc, action) => {
        if (!action.type) {
            return acc;
        }
        if (acc.includes(action.type)) {
            return acc;
        }
        return [
            ...acc,
            action.type,
        ]
    }, []);
}

const getActionsByCategory = (actions, category) => {
    return actions.filter(action => action.type === category)
}
const getCurrentActions = (data, pageNumber, pageSize) => {
    let begin = ((pageNumber - 1) * pageSize);
    let end = begin + pageSize;
    return data.slice(begin, end);
}
const getPageCount = (data, pageSize) => {
    return Math.ceil(data.length / pageSize);
}