import api from 'config/api';
// Types
export const types = {
    GET_LIST_REQUEST: "entities/getListRequest",
    GET_LIST_RECEIVE: "entities/getListReceive",
    GET_LIST_FAILURE: "entities/getListFailure",
    
    SELECT_ENTITY: "entities/selectEntity",
}
// Actions
export const selectEntity = entity => ({
    type: types.SELECT_ENTITY,
    payload: {
        entity,
    }
});
export const getListRequest = () => ({ type: types.GET_LIST_REQUEST, });
export const getListReceive = list => ({
    type: types.GET_LIST_RECEIVE,
    payload: {
        list,
    }
});
export const getListFailure = error => ({
    type: types.GET_LIST_FAILURE,
    payload: {
        error,
    }
});
// Thunks
export const getEntities = () => (dispatch) => {
    dispatch(getListRequest());
    api.get(api.defaults.GET_ENTITIES)
        .then((response) => {
            const entities = response.data;
            dispatch(getListReceive(entities));
        })
        .catch(error => {
            dispatch(getListFailure(error.message));
        })
}