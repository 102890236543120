import api from 'config/api';
import { navigate } from "@reach/router"

// Types
export const types = {
    GET_ENTITIES_REQUEST: "entities/getEntitiesRequest",
    GET_ENTITIES_RECEIVE: "entities/getEntitiesReceive",
    GET_ENTITIES_FAILURE: "entities/getEntitiesFailure",

    GET_ENTITY_REQUEST: "entities/getEntityRequest",
    GET_ENTITY_RECEIVE: "entities/getEntityReceive",
    GET_ENTITY_FAILURE: "entities/getEntityFailure",

    CREATE_ENTITY_REQUEST: "entities/createEntityRequest",
    CREATE_ENTITY_RECEIVE: "entities/createEntityReceive",
    CREATE_ENTITY_FAILURE: "entities/createEntityFailure",

    DELETE_ENTITY_REQUEST: "entities/deleteEntityRequest",
    DELETE_ENTITY_RECEIVE: "entities/deleteEntityReceive",
    DELETE_ENTITY_FAILURE: "entities/deleteEntityFailure",

    EDIT_ENTITY_REQUEST: "entities/editEntityRequest",
    EDIT_ENTITY_RECEIVE: "entities/editEntityReceive",
    EDIT_ENTITY_FAILURE: "entities/editEntityFailure",
}

// Actions
export const getEntitiesRequest = () => ({ type: types.GET_ENTITIES_REQUEST, });

export const getEntitiesReceive = entities => ({
    type: types.GET_ENTITIES_RECEIVE,
    payload: {
        entities,
    }
});

export const getEntitiesFailure = error => ({
    type: types.GET_ENTITIES_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getEntities = (tenant) => (dispatch) => {
    dispatch(getEntitiesRequest());

    api.get(`${api.defaults.ADMIN_ENTITY}/${tenant}`)
        .then((response) => {
            const entities = response.data;
            dispatch(getEntitiesReceive(entities));
        })
        .catch(error => {
            dispatch(getEntitiesFailure(error.message));
        })
}


export const createEntityRequest = () => ({ type: types.CREATE_ENTITY_REQUEST, });
export const createEntityReceive = () => ({ type: types.CREATE_ENTITY_RECEIVE, });
export const createEntityFailure = error => ({
    type: types.CREATE_ENTITY_FAILURE,
    payload: {
        error,
    }
});

// Thunks

export const createEntity = ({ tenantId, entity }) => (dispatch) => {
    dispatch(createEntityRequest());

    api.post(`${api.defaults.ADMIN_ENTITY}/${tenantId}`, entity)
        .then(() => {
            dispatch(createEntityReceive());
            dispatch(getEntities());
            navigate(`/admin/entities`)
        })
        .catch(error => {
            dispatch(createEntityFailure(error.message));
        })
}


export const deleteEntityRequest = (id) => ({ 
    type: types.DELETE_ENTITY_REQUEST,
    payload: {
        id
    }
});
export const deleteEntityReceive = (id) => ({ 
    type: types.DELETE_ENTITY_RECEIVE, 
    payload: {
        id
    }
});
export const deleteEntityFailure = (error, id) => ({
    type: types.DELETE_ENTITY_FAILURE,
    payload: {
        error,
        id
    }
});

// Thunks
export const deleteEntity = (tenant, id) => (dispatch) => {
    dispatch(deleteEntityRequest(id));

    api.delete(`${api.defaults.ADMIN_ENTITY}/${tenant}/${id}`)
        .then(() => {
            dispatch(deleteEntityReceive(id));
            dispatch(getEntities(tenant));
            navigate(`/admin/entities`)
        })
        .catch(error => {
            dispatch(deleteEntityFailure(error.message, id));
        })
}


export const editEntityRequest = () => ({ type: types.EDIT_ENTITY_REQUEST, });
export const editEntityReceive = () => ({ type: types.EDIT_ENTITY_RECEIVE, });
export const editEntityFailure = error => ({
    type: types.EDIT_ENTITY_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const editEntity = (tenant, entity) => (dispatch) => {
    dispatch(editEntityRequest());

    api.put(`${api.defaults.ADMIN_ENTITY}/${tenant}`, entity)
        .then(() => {
            dispatch(editEntityReceive());
            dispatch(getEntities());
            navigate(`/admin/entities`)
        })
        .catch(error => {
            dispatch(editEntityFailure(error.message));
        })
}


export const getEntityRequest = () => ({ type: types.GET_ENTITY_REQUEST, });
export const getEntityReceive = entity => ({
    type: types.GET_ENTITY_RECEIVE,
    payload: {
        entity,
    }
});
export const getEntityFailure = error => ({
    type: types.GET_ENTITY_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getEntity = (tenant, entity) => (dispatch) => {
    dispatch(getEntityRequest());

    api.get(`${api.defaults.ADMIN_ENTITY}/${tenant}/${entity}`)
        .then((response) => {
            const entity = response.data;
            dispatch(getEntityReceive(entity));
        })
        .catch(error => {
            dispatch(getEntityFailure(error.message));
        })
}
