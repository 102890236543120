import { types } from "actions/tenants";

const INITIAL_STATE = {
    isFetching: false,
    isSelecting: false,
    list: [],
    selected: {},
    error: undefined,
    errorSelecting: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SELECT_TENANT_REQUEST:
            return {
                ...state,
                isSelecting: true,
                errorSelecting: undefined,
            }            
        case types.SELECT_TENANT_CONFIRM:
            if (!action.payload.tenant) {
                sessionStorage.removeItem('tenantId');

                return {
                    ...state,
                    selected: {}
                }
            }

            sessionStorage.setItem('tenantId', action.payload.tenant.id);

            return {
                ...state,
                isSelecting: false,
                selected: action.payload.tenant
            }            
        case types.SELECT_TENANT_FAILURE:
            return {
                ...state,
                isSelecting: false,
                error: action.payload.error,
            }
        case types.GET_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_LIST_RECEIVE:
            return {
                ...state,
                isFetching: false,
                list: action.payload.list
            }
        case types.GET_LIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            }
        default:
            return state
    }
}
