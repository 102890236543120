import { msalAcquireSilentToken } from 'actions/auth';
import axios from 'axios';

//const BASE_URL = "https://euwuintgxbwap01.azurewebsites.net/api"
//const BASE_URL = "https://rrh-nonprod.ey.com/api"
//const BASE_URL = "https://localhost:5001/api"
//const BASE_URL = "https://anacredittestapp.azurewebsites.net/api"
const BASE_URL = process.env.REACT_APP_API_URI
console.log('base: ' + BASE_URL )

const ApiConfiguration = axios.create({
  baseURL: BASE_URL, 
  headers:  {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    //crossDomain: true,
    //"Set-Cookie": ".AspNetCore.Session=CfDJ8CdD1hDNgztLnjrTwcxqTV0UAKNAuU09ZzT0YWVSDFWl3GWytCSX4yIsLafOXqFNEpsaOJNyilom9WB40wDCe3gh41zj0%2BGBhF16X8G7GddZFjXPP8oBgfaNlDN72x67lVlGvAJYk4cU1uDNUBE0%2FqTVmDAtxOSIuyvsX%2FzBiwqy; path=/; samesite=none; secure; httponly",
    //"Set-Cookie": "ARRAffinity=1405d982b4a15efe34b44fcc99eada29e06b00e246255a873dd8b91fc472b07c;Path=/;HttpOnly;Secure;Domain=anacredittestapp.azurewebsites.net",
    //"Set-Cookie": "ARRAffinitySameSite=1405d982b4a15efe34b44fcc99eada29e06b00e246255a873dd8b91fc472b07c;Path=/;HttpOnly;SameSite=None;Secure;Domain=anacredittestapp.azurewebsites.net",
  },
  withCredentials: true,
  GET_ENTITIES: `${BASE_URL}/tenant/entities`,
  GET_TENANTS: `${BASE_URL}/tenants`,
  SET_TENANTS: `${BASE_URL}/settenant`,
  UPLOAD_URL: `${BASE_URL}/reports/upload`,
  REPORTS_URL: `${BASE_URL}/reports`,
  DELETE_URL: `${BASE_URL}/reports`,
  DOWNLOAD_URL: `${BASE_URL}/reports/download`,
  GENERATE_REPORT: `${BASE_URL}/reports/generate`,
  GENERATE_TEMPLATE: `${BASE_URL}/corefiling-admin/generate-templates`,
  POPULATE_PIPELINE: `${BASE_URL}/corefiling-admin/populate-pipeline`,
  POPULATE_DATAPOINT: `${BASE_URL}/corefiling-admin/populate-datapoint`,
  GET_NOTIFICATIONS_URL: `${BASE_URL}/actions/finished_unnoticed`,
  NOTICE_URL: `${BASE_URL}/actions/notice`,
  GET_ACTIONS: `${BASE_URL}/actions`,
  GET_LOGS: `${BASE_URL}/logs`,
  GET_PIPELINES: `${BASE_URL}/reports/pipelines`,
  GET_ADMIN_PROCESSORS: `${BASE_URL}/corefiling-admin/processors`,
  GET_ADMIN_PIPELINES: `${BASE_URL}/corefiling-admin/pipeline`,
  GET_ADMIN_SCHEMA_REFS: `${BASE_URL}/corefiling-admin/schemarefs`,
  DOWNLOAD_TEMPLATES: `${BASE_URL}/reports/template/download`,
  GET_RULES: `${BASE_URL}/validation/ruleset`,
  ADMIN_TENANT: `${BASE_URL}/admin/tenant`,
  ADMIN_USER: `${BASE_URL}/admin/user`,
  ADMIN_MAPPING: `${BASE_URL}/admin/tenant-user`,
  ADMIN_ENTITY: `${BASE_URL}/admin/entity`,
  CHECK_REPORT_SUBMISSION_STATUS: `${BASE_URL}/reports/submissions`,
  CHECK_VALIDATION_SUBMISSION_STATUS: `${BASE_URL}/validation/submissions`,
  ADMIN_POWER_BI: `${BASE_URL}/admin/powerbi`,
  VALIDATE: `${BASE_URL}/validation`,
  GET_RESULTS: `${BASE_URL}/validation/runs`,
  VALIDATION_REPORT_DOWNLOAD: `${BASE_URL}/validation/download`,
  GET_USER_PERMISSIONS: `${BASE_URL}/tenant/user/permissions`,
  GET_VALIDATION_REPORTS: `${BASE_URL}/validation/reports`,
  GET_VALIDATION_REPORTS: `${BASE_URL}/validation/reports`,
  EMBED_POWER_BI: `${BASE_URL}/powerbi/report`,
})

ApiConfiguration.interceptors.request.use(async config => {
  let accessToken;
  const username = sessionStorage.getItem('username');

  try {
    const data = await msalAcquireSilentToken(username);

    accessToken = data.accessToken;
  } catch(e) {
    accessToken = sessionStorage.getItem('token');
  }

  const tenantId = sessionStorage.getItem('tenantId');
  const entityId = sessionStorage.getItem('entityId');

  // Do something before request is sent
  return {
    ...config,
    headers: {
      ...config.headers,
      "Authorization": `Bearer ${accessToken}`,
      ...(tenantId && { "Tenant-Id": tenantId }),
      ...(entityId && { "Entity-Id": entityId })
    }
  }
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default ApiConfiguration;