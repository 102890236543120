import React from 'react';
import classnames from "classnames";
import styles from "./styles.module.css";

export const CardHeader = ({
    heading: Heading = "h1",
    headingId,
    title,
    classes: {
        rootClassName,
        titleClassName,
    } = {},
    beforeTitle,
    leftChildren,
    rightChildren,
}) => {
    return (
        <header 
            className={ classnames(styles.header, rootClassName) }
        >
            <div className={ styles.left }>
                { beforeTitle }
                <Heading
                    id={ headingId }
                    className={ classnames(styles.heading, titleClassName)}
                >
                    { title }
                </Heading>
                { leftChildren }
            </div>
            <div className={ styles.right }>
                { rightChildren }
            </div>
        </header>
    )
}

export const CardContent = ({
    className,
    children
}) => {
    return (
        <div className={ classnames(styles.content, className) }>
            { children }
        </div>        
    )
}

export const CardFooter = ({
    className,
    children
}) => {
    return (
        <footer className={ classnames(styles.footer, className) }>
            { children }
        </footer>
    )
}

export const CardDescription = ({
    descriptionId,
    className,
    children
}) => {
    return (
        <p
            id={ descriptionId }
            className={ classnames(styles.description, className) }
        >
            { children }
        </p>
    )
}

export const CardNote = ({
    className,
    children
}) => {
    return (
        <div
            className={ classnames(styles.note, className) }
        >
            { children }
        </div>
    )
}

const Card = ({
    element: Element = "div",
    className,
    children,
}) => {
    return (
        <Element className={ classnames(styles.card, className) }>
            { children }
        </Element>
    )
}

export default Card;
