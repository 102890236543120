import api from 'config/api';
import { navigate } from "@reach/router"

// Types
export const types = {
    GET_USERS_REQUEST: "users/getUsersRequest",
    GET_USERS_RECEIVE: "users/getUsersReceive",
    GET_USERS_FAILURE: "users/getUsersFailure",

    GET_USER_REQUEST: "users/getUserRequest",
    GET_USER_RECEIVE: "users/getUserReceive",
    GET_USER_FAILURE: "users/getUserFailure",

    CREATE_USER_REQUEST: "users/createUserRequest",
    CREATE_USER_RECEIVE: "users/createUserReceive",
    CREATE_USER_FAILURE: "users/createUserFailure",

    DELETE_USER_REQUEST: "users/deleteUserRequest",
    DELETE_USER_RECEIVE: "users/deleteUserReceive",
    DELETE_USER_FAILURE: "users/deleteUserFailure",

    EDIT_USER_REQUEST: "users/editUserRequest",
    EDIT_USER_RECEIVE: "users/editUserReceive",
    EDIT_USER_FAILURE: "users/editUserFailure",
}

// Actions
export const getUsersRequest = () => ({ type: types.GET_USERS_REQUEST, });

export const getUsersReceive = users => ({
    type: types.GET_USERS_RECEIVE,
    payload: {
        users,
    }
});

export const getUsersFailure = error => ({
    type: types.GET_USERS_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getUsers = () => (dispatch) => {
    dispatch(getUsersRequest());

    api.get(api.defaults.ADMIN_USER)
        .then((response) => {
            const users = response.data;
            dispatch(getUsersReceive(users));
        })
        .catch(error => {
            dispatch(getUsersFailure(error.message));
        })
}


export const createUserRequest = () => ({ type: types.CREATE_USER_REQUEST, });
export const createUserReceive = () => ({ type: types.CREATE_USER_RECEIVE, });
export const createUserFailure = error => ({
    type: types.CREATE_USER_FAILURE,
    payload: {
        error,
    }
});

// Thunks

export const createUser = (user) => (dispatch) => {
    dispatch(createUserRequest());

    api.post(api.defaults.ADMIN_USER, user)
        .then(() => {
            dispatch(createUserReceive());
            dispatch(getUsers());
            navigate(`/admin/users`)
        })
        .catch(error => {
            dispatch(createUserFailure(error.message));
        })
}


export const deleteUserRequest = (id) => ({ 
    type: types.DELETE_USER_REQUEST,
    payload: {
        id
    }
});
export const deleteUserReceive = (id) => ({ 
    type: types.DELETE_USER_RECEIVE, 
    payload: {
        id
    }
});
export const deleteUserFailure = (error, id) => ({
    type: types.DELETE_USER_FAILURE,
    payload: {
        error,
        id
    }
});

// Thunks
export const deleteUser = (id) => (dispatch) => {
    dispatch(deleteUserRequest(id));

    api.delete(`${api.defaults.ADMIN_USER}/${id}`)
        .then(() => {
            dispatch(deleteUserReceive(id));
            dispatch(getUsers());
            navigate(`/admin/users`)
        })
        .catch(error => {
            dispatch(deleteUserFailure(error.message, id));
        })
}


export const editUserRequest = () => ({ type: types.EDIT_USER_REQUEST, });
export const editUserReceive = () => ({ type: types.EDIT_USER_RECEIVE, });
export const editUserFailure = error => ({
    type: types.EDIT_USER_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const editUser = (user) => (dispatch) => {
    dispatch(editUserRequest());

    api.put(`${api.defaults.ADMIN_USER}`, user)
        .then(() => {
            dispatch(editUserReceive());
            dispatch(getUsers());
            navigate(`/admin/users`)
        })
        .catch(error => {
            dispatch(editUserFailure(error.message));
        })
}


export const getUserRequest = () => ({ type: types.GET_USER_REQUEST, });

export const getUserReceive = user => ({
    type: types.GET_USER_RECEIVE,
    payload: {
        user,
    }
});

export const getUserFailure = error => ({
    type: types.GET_USER_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getUser = (user) => (dispatch) => {
    dispatch(getUserRequest());

    api.get(`${api.defaults.ADMIN_USER}/${user}`)
        .then((response) => {
            const user = response.data;
            dispatch(getUserReceive(user));
        })
        .catch(error => {
            dispatch(getUserFailure(error.message));
        })
}
