import { DateTime } from "luxon";

const formatDate = (isoDate, format) => {
    const luxonDate = DateTime.fromISO(isoDate);
    if (luxonDate.isValid) {
        return luxonDate.toFormat(format);
    }
    return "";
};

export const DEFAULT_FORMAT = "dd-MM-yyyy";
export const DATE_AND_TIME_FORMAT = "hh:mm dd-MM-yyyy";
export const PRECISE_DATE_AND_TIME_FORMAT = "hh:mm:ss dd-MM-yyyy";

export default formatDate;
