import { useState, useEffect } from 'react';
import api from 'config/api';

import {
    replaceItemAtIndex,
    removeItemAtIndex,
} from "utils/helpers";

export default function useQueue() {
    const [config] = useState(api.defaults);

    const [files, setFiles] = useState([]);
    const [queueFiles, setQueueFiles] = useState([]);    

    const [statuses, setStatuses] = useState(new Map());
    const [percentagesByFile, setPercentagesByFile] = useState(new Map());

    const [isRunning, setIsRunning] = useState(false);
    const [hasSucceeded, setHasSucceeded] = useState(false);
    const [reRunQueue, setReRunQueue] = useState();
    
    const uploadReport = (file) => {
        const formData = new FormData();

        formData.append("report", file, file.name);

        api.post(
            config.UPLOAD_URL, 
            formData,
            {
                onUploadProgress: ev => {
                    const nextPercentagesByFile = new Map(percentagesByFile);
                    const percentage = Math.floor((ev.loaded * 100) / ev.total);
                    nextPercentagesByFile.set(file.name, percentage);

                    setPercentagesByFile(nextPercentagesByFile)
                }
            }
        )
        .then(res => {
            const reportSubmissionId = res.data.actionId;
       
            if(reportSubmissionId) {
                let reportSubmissionIds = [];
                if(sessionStorage.getItem('reportSubmissionIds')) {
                    reportSubmissionIds = JSON.parse(sessionStorage.getItem('reportSubmissionIds'));
                    reportSubmissionIds.push(reportSubmissionId);
                    sessionStorage.setItem("reportSubmissionIds", JSON.stringify(reportSubmissionIds));
                } else {
                    reportSubmissionIds.push(reportSubmissionId)
                    sessionStorage.setItem("reportSubmissionIds", JSON.stringify(reportSubmissionIds));
                }
            }
            const nextStatuses = new Map(statuses);
            nextStatuses.set(file.name, "SUCCEED");

            setStatuses(nextStatuses)
            setHasSucceeded(true)

        })
        .catch(err => {
            const nextStatuses = new Map(statuses);
            const nextPercentagesByFile = new Map(percentagesByFile);

            nextStatuses.set(file.name, "ERROR");
            nextPercentagesByFile.set(file.name, 0);

            setStatuses(nextStatuses)
            setPercentagesByFile(nextPercentagesByFile)
        })
        .finally(() => setReRunQueue(Date.now()))


    }

    const run = () => {
        const nextQueueFiles = [ ...queueFiles ];
        const file = nextQueueFiles.shift();

        if (!file) {
            setIsRunning(false)
            return;
        } else {
            setIsRunning(true)
        }
        
        setQueueFiles(nextQueueFiles);
        uploadReport(file);
    }

    const retry = (name, file) => {
        const nextStatuses = new Map(statuses);
        const nextQueueFiles = [...queueFiles];

        nextStatuses.delete(name);
        nextQueueFiles.push(file);

        setStatuses(nextStatuses)
        setQueueFiles(nextQueueFiles);

        if (!isRunning) {
            setReRunQueue(Date.now());
        }
    }

    const removeElement = index => () => {
        const nextStatuses = new Map(statuses);
        let nextFiles = [...files];
        let nextQueueFiles = [...queueFiles];

        const filename = nextFiles[index].name;

        nextFiles = removeItemAtIndex(nextFiles, index);
        nextQueueFiles = removeItemAtIndex(nextQueueFiles, index);
        nextStatuses.delete(filename);

        setFiles(nextFiles);
        setQueueFiles(nextQueueFiles);
        setStatuses(nextStatuses);
    }

    const addFiles = filesSelected => {
        let nextFiles = [...files];
        let nextQueueFiles = [...queueFiles];

        Array.from(filesSelected, (file) => {
            const indexToReplace = nextFiles.findIndex(prevFile => prevFile.name === file.name);

            if (indexToReplace !== -1) {
                nextFiles = replaceItemAtIndex(nextFiles, file, indexToReplace);
                nextQueueFiles = replaceItemAtIndex(nextQueueFiles, file, indexToReplace);
            } else {
                nextFiles.push(file);
                nextQueueFiles.push(file);
            }
        });

        setFiles(nextFiles);
        setQueueFiles(nextQueueFiles);
    }

    useEffect(() => {
        if (reRunQueue) {
            run()
        }
    }, [reRunQueue]);

    return {
        isRunning,
        hasSucceeded,
        isEmpty: !queueFiles.length,

        getFiles: files,
        getStatusMap: statuses,
        getPercentagesMap: percentagesByFile,

        run,
        retry,
        removeElement,
        addFiles
    }
}
