import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { getReports } from "actions/validations";

// Components
import { Table, Checkbox, Header } from "components";

// Own Components
import { Filters } from "./components";

// Assets
import styles from "./styles.module.css";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function Reports({
    manageReportId
}) {

    const dispatch = useDispatch();    
    const { isFetchingReports, reportList } = useSelector(state => state.validations);

    useEffect(() => {
        dispatch(getReports());
    }, []);    

    return (
        <>
            <Header
                heading="h2"
                title="Select Reports"
                classes={ HEADER_CLASSES }
                rightChildren={ <Filters /> }
            />
            <Table
                emptyMessage="Please upload a report"
                isLoadingData={ isFetchingReports }
                minWidth={ 400 }
                widths={ [400, 100, 100] }
                headItems={ ["File name", "Report type", "Version"] }
                bodyItems={
                    reportList.map(({ type, version, id, filename }) => ([
                        <Checkbox
                            key={id}
                            onChange={ manageReportId }
                            value={id}
                            label={filename}
                        />,
                        type,
                        version,
                    ]))
                }
            />
        </>
    )
}