import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
    FileIcon,
    Button,
    Card,
    InputFile,
    Modal,
} from 'components';
import { CardHeader, CardContent, CardFooter, CardDescription, CardNote } from "components/Card";

// Hooks
import useQueue from "./hooks/useQueue";

// Actions
import * as reportActions from "actions/reports";

// Assets
import styles from "./styles.module.css";

const INPUT_FILE_CLASSES = {
    rootClassName: styles.inputFile
}

function ModalUpload() {
    const dispatch = useDispatch();

    const queue = useQueue();
   
    const closeUploadModal = () => {
        dispatch(reportActions.closeUploadModal())
    }

    return (
        <Modal onClose={ closeUploadModal }>
            <Card
                className={ styles.card }
                element="section"
            >
                <CardHeader
                    heading="h2"
                    title="Upload Reports"
                    rightChildren={
                        <button
                            className={ styles.close }
                            onClick={ closeUploadModal }
                            aria-label="Close upload report form"
                        >
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    }
                />
                <CardContent>
                    <CardDescription>
                        {
                            queue.hasSucceeded
                                ? "Processing of the uploaded file has started this can take some time."
                                : "Select and upload the files for processing."
                        }
                    </CardDescription>
                    <CardNote
                        className={ styles.note }
                    >
                        <b>Note:</b> Please select a valid <b>.xbrl</b> or <b>.xlsx</b> file
                    </CardNote>
                    <div className={ styles.list }>
                        {
                            queue.getFiles.map((file, i) => (
                                <FileIcon
                                    key={ file.name }
                                    file={ file }
                                    status={ queue.getStatusMap.get(file.name) }
                                    progress={ queue.getPercentagesMap.get(file.name) }
                                    onRetry={ queue.retry }
                                    onDelete={ queue.removeElement(i) }
                                />
                            ))
                        }
                    </div>
                    <InputFile
                        classes={ INPUT_FILE_CLASSES }
                        onFilesSelected={ queue.addFiles }
                        multiple={ true }
                        accept=".xlsx,.xbrl,.xlsm"
                    />
                </CardContent>
                <CardFooter>
                    <Button
                        disabled={ queue.hasSucceeded || queue.isEmpty || queue.isRunning }
                        onClick={ queue.run }
                        value = "Done"np
                    >
                        {
                            queue.hasSucceeded
                                ? "Upload sucess"
                                : "Upload"
                        }
                    </Button>   
                </CardFooter>
            </Card>
        </Modal>
    )
}

export default function Relay() {
    const isOpen = useSelector(state =>
        state.reports.catalog.isUploadReportModalOpen
    );

    if (isOpen) {
        return (
            <ModalUpload />
        );
    }

    return null;
}
