import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from 'formik'

// Components
import { Header, Button, FormError } from "components";

// Actions
import * as actionsUsers from "actions/admin/users";

// Assets
import styles from "../styles.module.css";

import { validationSchema } from "./validations";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

const initialValues = {
    objectId: '',
    firstname: '',
    lastname: '',
    email: '',
    superAdmin: 'false',
    entityManager: 'false',
    coreFiling: 'false'
}

const stringToBoolean = (string) => {
    return string === 'true' ? true : false;
}

export default function UserCreate() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionsUsers.getUser(3));
    }, [])

    const isSuperAdmin = useSelector(state => state.auth.isSuperAdmin);

    const onSubmit = (user) => {        
        user['superAdmin'] = stringToBoolean(user['superAdmin'])
        user['entityManager'] = stringToBoolean(user['entityManager'])
        user['coreFiling'] = stringToBoolean(user['coreFiling'])

        dispatch(actionsUsers.createUser(user));        
    }

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Create a new User"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/users') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>
                }
            />

            <Formik
                initialValues={ initialValues }
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {

                    const { userType } = formik.values;

                    return (                
                        <Form>
                            <div className={styles.formControl}>
                                <label htmlFor="objectId">ObjectId</label>
                                <Field 
                                    type='text'
                                    name='objectId'
                                    autoComplete="off"
                                />
                                <ErrorMessage 
                                    name='objectId'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="firstname">Firstname</label>
                                <Field 
                                    type='text'
                                    name='firstname'
                                    autoComplete="off"
                                />
                                <ErrorMessage 
                                    name='firstname'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="lastname">Lastname</label>
                                <Field
                                    type='text'
                                    name='lastname'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='lastname'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="email">Email</label>
                                <Field
                                    type='email'
                                    name='email'
                                />
                                <ErrorMessage
                                    name='email'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="userType">User Type</label>
                                <Field
                                    as="select"
                                    name="userType"
                                    className={ styles.select }
                                >
                                    <option value=""> Select a user type</option>
                                    <option value="default">Default</option> 
                                    {
                                        isSuperAdmin && (
                                            <>
                                                <option value="admin">Admin</option>
                                                <option value="all">All</option>
                                            </>                                            
                                        )
                                    }                                    
                                </Field>
                                <ErrorMessage
                                    name='userType'
                                    component={FormError}
                                />
                            </div>
                            {
                                (userType == 'admin' || userType == 'all') && (
                                    <div className={styles.formControl}>
                                        <label className={styles.label} htmlFor="superAdmin">Super Admin</label>
                                        
                                        <Field
                                            type='radio'
                                            value='true'
                                            name='superAdmin'
                                        />
                                        <label htmlFor="superAdmin">Yes</label>

                                        <Field
                                            type='radio'
                                            value='false'
                                            name='superAdmin'
                                        />
                                        <label htmlFor="superAdmin">No</label>
                                    </div>
                                )
                            }
                            {
                                (userType == 'default' || userType == 'all') && (
                                    <>
                                        <div className={styles.formControl}>
                                            <label className={styles.label} htmlFor="entityManager">Entity Manager</label>
                                            
                                            <Field
                                                type='radio'
                                                value='true'
                                                name='entityManager'
                                            />
                                            <label htmlFor="entityManager">Yes</label>

                                            <Field
                                                type='radio'
                                                value='false'
                                                name='entityManager'
                                            />
                                            <label htmlFor="entityManager">No</label>
                                        </div>

                                        <div className={styles.formControl}>
                                            <label className={styles.label} htmlFor="coreFiling">Core Filing</label>
                                            
                                            <Field
                                                type='radio'
                                                value='true'
                                                name='coreFiling'
                                            />
                                            <label htmlFor="coreFiling">Yes</label>

                                            <Field
                                                type='radio'
                                                value='false'
                                                name='coreFiling'
                                            />
                                            <label htmlFor="coreFiling">No</label>
                                        </div>
                                    </>                                    
                                )
                            }                           
                            
                            <div className={ styles.formFooter }>                            
                                <Button
                                    type='submit'
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    Submit
                                </Button>                                                
                            </div>                       
                        </Form>
                    )
                }}
            </Formik>
        </main>
    )
}