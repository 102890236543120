import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { getReportsCatalog } from "actions/reports";

// Own Components
import { ReportsTable } from "./components";

// Components
import ReportHeader from "components/ReportHeader";

export default function AllFiles() {
    const dispatch = useDispatch();

    const error = useSelector(state => state.reports.catalog.error);

    useEffect(() => {
        dispatch(getReportsCatalog());
    }, []);

    return (
        <section>              
            <ReportHeader />
            {
                error && (
                    <span>Unexpected Error</span>
                )
            }
            {
                !error && (
                    <ReportsTable />
                )
            }
        </section>
    )
}
