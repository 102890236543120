import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardColumns, Container } from 'react-bootstrap';
import { navigate } from "@reach/router"

import * as actionsLogs from "actions/logs";

// Assets
import styles from "./styles.module.css";

// Components
import Category from './components/Category'

export default function Logs() {
    const dispatch = useDispatch();
    const uniqueActionTypes = useSelector(state => state.logs.uniqueActionTypes);
    const isFetchingActions = useSelector(state => state.logs.isFetchingActions);

    useEffect(() => {
        dispatch(actionsLogs.getActions());
    }, []);

    const selectCategory = (category) => {
        navigate(`/logs/${category}`)
    };

    return (
        <main className={ styles.view }>
            <h2 className="log-title"> { isFetchingActions ? 'Loading...' : 'Select a Log Category' }</h2>
            {
                isFetchingActions && (                   
                    <CardColumns className="log-categories loading-wrapper">
                        <Category category="Skeleton" />
                        <Category category="Skeleton" />
                        <Category category="Skeleton" />
                        <Category category="Skeleton" />
                    </CardColumns>
                )
            }

            {
                !isFetchingActions && (
                    <CardColumns className="log-categories">
                        {
                            uniqueActionTypes.map((actionType, index) => (
                                    <Category selectCategory={() => selectCategory(actionType)} category={actionType} key={index} />
                            ))
                        }
                        {
                            uniqueActionTypes.length === 0 && (
                                <div>
                                    No record found
                                </div>
                            )
                        }
                    </CardColumns>
                )
            }
        </main>
    )
}