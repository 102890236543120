import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from 'formik'

// Components
import { Header, Button, FormError } from "components";

// Actions
import * as actionsEntities from "actions/admin/entities";

// Assets
import styles from "../styles.module.css";

import { validationSchema } from "../validations";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function EntityEdit({
    id
}) {

    const dispatch = useDispatch();
    const { tenantId } = useSelector(state => state.admin.tenants);
    const { entity } = useSelector(state => state.admin.entities);

    const onSubmit = (entity) => {
        dispatch(actionsEntities.editEntity(tenantId, entity));
    }

    useEffect(() => {
        dispatch(actionsEntities.getEntity(tenantId, id));
    }, [])

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Edit Entity"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/entities') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>
                }
            />

            <Formik
                initialValues={entity}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(formik) => {
                    return (                
                        <Form>
                            <div className={styles.formControl}>
                                <label htmlFor="name">Name</label>
                                <Field 
                                    type='text'
                                    id='name'
                                    name='name'
                                    autoComplete="off"
                                />
                                <ErrorMessage 
                                    name='name'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="reportingLevel">Reporting Level</label>
                                <Field
                                    type='text'
                                    id='reportingLevel'
                                    name='reportingLevel'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='reportingLevel'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="xbrlReportingLevel">Xbrl Reporting Level</label>
                                <Field
                                    type='text'
                                    id='xbrlReportingLevel'
                                    name='xbrlReportingLevel'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='xbrlReportingLevel'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="xbrlIdentifier">Xbrl Identifier</label>
                                <Field
                                    type='text'
                                    id='xbrlIdentifier'
                                    name='xbrlIdentifier'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='xbrlIdentifier'
                                    component={FormError}
                                />
                            </div>
                            <div className={ styles.formFooter }>                            
                                <Button
                                    type='submit'
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    Submit
                                </Button>                                                
                            </div>                       
                        </Form>
                    )
                }}
            </Formik>
        </main>
    )
}