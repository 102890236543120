import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';
import formatDate, { DEFAULT_FORMAT } from "utils/formatDate";

// Actions
import * as validationActions from "actions/validations";

// Components
import {
    Card,
    Modal,
} from 'components';
import { CardHeader, CardContent, CardFooter } from "components/Card";

// Assets
import styles from "./styles.module.css";

const TIME = "HH:MM";

function ModalDetails() {
    const dispatch = useDispatch();

    const {
        id,
        user,
        ruleSets,
        reports,
        runDate,
        validationResultFiles
    } = useSelector(state =>
        state.validations.result
    );
   
    const closeModal = () => {
        dispatch(validationActions.closeResultDetailsModal());
    };

    const downloadReport = (id) => {
        dispatch(validationActions.downloadReport(id));
    }
    
    return (
        <Modal onClose={ closeModal }>
            <Card
                className={ styles.card }
                element="section"
            >
                <CardHeader
                    heading="h2"
                    rightChildren={
                        <button
                            className={ styles.close }
                            onClick={ closeModal }
                            aria-label="Close"
                        >
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    }
                />
                <CardContent>
                    <>
                        <Row>
                            <Col md={9}>
                                <Row>
                                    <Col md={5}>
                                        <label>Validation GUID: </label> {id}
                                    </Col>
                                    <Col md={2}>
                                        <label>User: </label> {user}
                                    </Col>
                                    <Col md={4}>
                                        <label>Date Time: </label> { formatDate(runDate, `${DEFAULT_FORMAT} ${TIME}`) }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <label>Reports: </label>
                                        {
                                            reports.split('|').map((report) => (
                                                <div> - { report }</div>
                                            ))
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <label>Rule sets: </label>
                                        {
                                            ruleSets.split('|').map((ruleSet) => (
                                                <div> - { ruleSet }</div>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <label>Results: </label>                                
                                {
                                    validationResultFiles.map((report) => (
                                        <div> 
                                        - {report.filename} 
                                        <div>
                                            <Link to="" onClick={ () => downloadReport(report.id) }>
                                                Download
                                            </Link>
                                        </div>
                                    </div>
                                    ))
                                }
                            </Col>
                        </Row>
                    </>
                </CardContent>
                <CardFooter></CardFooter>
            </Card>
        </Modal>
    )
}

export default function Relay() {
    const result = useSelector(state =>
        state.validations.result
    );

    if (result) {
        return (
            <ModalDetails />
        )
    }

    return null;
}
