import api from 'config/api';

export const types = {
    GET_NOTIFICATIONS_REQUEST: "notifications/getNotificationsRequest",
    GET_NOTIFICATIONS_RECEIVE: "notifications/getNotificationsReceive",
    GET_NOTIFICATIONS_FAILURE: "notifications/getNotificationsFailure",
}

export const getNotificationsRequest = () => ({ type: types.GET_NOTIFICATIONS_REQUEST, });

export const getNotificationsReceive = notifications => ({
    type: types.GET_NOTIFICATIONS_RECEIVE,
    payload: {
        notifications,
    }
});

export const getNotificationsFailure = error => ({
    type: types.GET_NOTIFICATIONS_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getNotifications = () => (dispatch) => {
    dispatch(getNotificationsRequest());

    api.get(`${api.defaults.GET_NOTIFICATIONS_URL}`)
        .then((response) => {
            const notifications = response.data;
            dispatch(getNotificationsReceive(notifications));
            
            if(notifications.length) {
                dispatch(updateNotificationReceivedStatus(notifications.map(notification => notification.id)));
            }            
        })
        .catch(error => {
            dispatch(getNotificationsFailure(error.message));
        })
}

export const updateNotificationReceivedStatus = (notificationsIds) => () => {
    api.post(api.defaults.NOTICE_URL, notificationsIds);
}