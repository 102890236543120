import { types } from "actions/admin/tenants";

const INITIAL_STATE = {
    isFetching: false,
    isCreating: false,
    tenants: [],
    tenant: {},
    tenantId: null,
    idsBeingDeleted: [],
    error: undefined,
    errorSelecting: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SELECT_TENANT_ID:
            return {
                ...state,
                tenantId: action.payload.tenantId
            }
        case types.GET_TENANTS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_TENANTS_RECEIVE:
            return {
                ...state,
                isFetching: false,                
                tenants: action.payload.tenants,
                tenant: {},
            }
        case types.GET_TENANTS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            }
        case types.CREATE_TENANT_REQUEST:
            return {
                ...state,
                isCreating: true,
                error: undefined,
            }
        case types.CREATE_TENANT_RECEIVE:
            return {
                ...state,
                isCreating: false
            }
        case types.CREATE_TENANT_FAILURE:
            return {
                ...state,
                isCreating: false,
                error: action.payload.error,
            }
        case types.DELETE_TENANT_REQUEST:
            return {
                ...state,
                idsBeingDeleted: [...state.idsBeingDeleted, action.payload.id],
                error: undefined,
            }
        case types.DELETE_TENANT_RECEIVE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id)
            }
        case types.DELETE_TENANT_FAILURE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id),
                error: action.payload.error,
            }
        case types.EDIT_TENANT_REQUEST:
            return {
                ...state,
                isEditing: true,
                error: undefined,
            }
        case types.EDIT_TENANT_RECEIVE:
            return {
                ...state,
                isEditing: false,
            }
        case types.EDIT_TENANT_FAILURE:
            return {
                ...state,
                isEditing: false,
                error: action.payload.error,
            }
        case types.GET_TENANT_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_TENANT_RECEIVE:
            return {
                ...state,
                isFetching: false,
                tenant: action.payload.tenant
            }
        case types.GET_TENANT_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
        }
        default:
            return state
    }
}
