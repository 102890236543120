import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { navigate } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from 'formik'

// Components
import { Header, Button, FormError } from "components";

// Actions
import * as actionsPowerbis from "actions/admin/powerbis";

// Assets
import styles from "../styles.module.css";

import { validationSchema } from "../validations";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

const initialValues = {
    reportId: '',
    clientId: '',
    workspaceId: '',
    tenantId: undefined,
    entityId: '',
    clientSecret: ''
}

export default function PowerbiCreate() {

    const dispatch = useDispatch();

    const onSubmit = (powerbi) => {
        powerbi['tenantId'] = parseInt(powerbi['tenantId'])
        dispatch(actionsPowerbis.createPowerbi(powerbi));        
    }

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Create a new Power BI"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/powerbis') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>
                }
            />

            <Formik
                initialValues={ initialValues }
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {
                    return (                
                        <Form>
                            <div className={styles.formControl}>
                                <label htmlFor="reportId">Report Id</label>
                                <Field 
                                    type='text'
                                    name='reportId'
                                    autoComplete="off"
                                />
                                <ErrorMessage 
                                    name='reportId'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="server">Client Id</label>
                                <Field
                                    type='text'
                                    name='clientId'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='clientId'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="workspaceId">Workspace Id</label>
                                <Field
                                    type='text'
                                    name='workspaceId'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='workspaceId'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="tenantId">Tenant Id</label>
                                <Field
                                    type='text'
                                    name='tenantId'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='tenantId'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="entityId">Entity Id</label>
                                <Field
                                    type='text'
                                    name='entityId'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='entityId'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="clientSecret">Client Secret</label>
                                <Field
                                    type='text'
                                    name='clientSecret'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='clientSecret'
                                    component={FormError}
                                />
                            </div>
                            <div className={ styles.formFooter }>                            
                                <Button
                                    type='submit'
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    Submit
                                </Button>                                                
                            </div>                       
                        </Form>
                    )
                }}
            </Formik>
        </main>
    )
}