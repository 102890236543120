import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from "@reach/router"

// Actions
import * as actionsTenants from "actions/tenants";
import * as actionsEntities from "actions/entities";

// Components
import Card, {
    CardHeader,
    CardContent,
    CardFooter,
    CardDescription,
} from "components/Card";

import styles from "./styles.module.css";

export default function Home() {
    const dispatch = useDispatch();

    const tenantsList = useSelector(state => state.tenants.list);
    const entitiesList = useSelector(state => state.entities.list);

    const selectedTenant = useSelector(state => state.tenants.selected);
    const selectedEntity = useSelector(state => state.entities.selected);

    const selectTenant = e => {
        const tenantId = e.target.value;
        const [ tenant ] = tenantsList.filter(tenant => tenant.id === Number(tenantId));

        dispatch(actionsTenants.selectTenant(tenant));
        dispatch(actionsEntities.selectEntity(null));
    }

    const selectEntity = e => {
        const entityId = e.target.value;
        const [ entity ] = entitiesList.filter(entity => entity.id === entityId);
        dispatch(actionsEntities.selectEntity(entity));

        if (selectedTenant?.id) {
            navigate("/reports")
        }
    }

    return (
        <div className={ styles.view }>
            <Card className={ styles.card }>
                <CardHeader
                    title="Tenant and Entity Selector"
                />
                <CardContent>
                    <CardDescription>
                        In order to start working please select a tenant and entity.
                    </CardDescription>
                </CardContent>
                <CardFooter>
                    <select
                        value={ selectedTenant?.id || "" }
                        onChange={ selectTenant }
                        className={ styles.select }
                    >
                        <option value="">
                            Select a tenant
                        </option>                        
                        {
                            tenantsList.map(({ id, name }) => (
                                <option
                                    key={ id }
                                    value={ id }
                                >
                                    { name }
                                </option>
                            ))
                        }                        
                    </select>

                    <select
                        value={ selectedEntity?.id || "" }
                        onChange={ selectEntity }
                        className={ styles.select }
                    >
                        <option value="">
                            Select a entity
                        </option>
                        {
                        entitiesList.map(({ id, name }) => (
                            <option
                                key={ id }
                                value={ id }
                            >
                                { name }
                            </option>
                            ))
                        }                        
                    </select>
                </CardFooter>
            </Card>
        </div>
    )
}
