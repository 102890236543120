import { types } from "actions/auth";

const INITIAL_STATE = {
    isAuthenticated: false,
    idToken: null,
    accessToken: null,
    idTokenClaims: {},
    
    error: null,
    isSigningIn: false,
    isLoggingOut: false,
    isAcquiringToken: false,
    account: {},
    currentStatus: "REAUTHENTICATING_USER",

    isFetchingPermissions: false,
    entityManager: false,
    coreFilling: false,
    isSuperAdmin: false,
    userType: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {            
        case types.LOGOUT_REQUEST:
            return {
                ...state,
                isLoggingOut: true,
                currentStatus: "LOGGING_OUT",
            }
        case types.LOGOUT_CONFIRM:
            sessionStorage.setItem('token', '');
            sessionStorage.setItem('username', '')
            sessionStorage.setItem('name', '')
            sessionStorage.setItem('tenantId', '')
            sessionStorage.setItem('entityId', '')
            
            return {
                ...state,
                isLoggingOut: false,
                currentStatus: "EMPTY_AUTH",
            }
        case types.LOGOUT_FAILURE:
            return {
                ...state,
                isLoggingOut: false,
                error: action.payload.error,
                currentStatus: "SIGNED_IN",
            }       
        
        case types.ACQUIRE_TOKEN_REQUEST:
            return {
                ...state,
                isAcquiringToken: true,
            }
        case types.SIGNIN_REQUEST:
            return {
                ...state,
                isSigningIn: true,
                currentStatus: "SIGNING_IN",
            }
        case types.ACQUIRE_TOKEN_RECEIVE:
        case types.SIGNIN_CONFIRM:
            sessionStorage.setItem('token', action.payload.accessToken);
            sessionStorage.setItem('username', action.payload.account.username);
            sessionStorage.setItem('name', action.payload.account.name);
            
            return {
                ...state,
                isSigningIn: false,
                isAcquiringToken: false,
                isAuthenticated: true,
                account: action.payload.account,
                accessToken: action.payload.accessToken,
                idTokenClaims: action.payload.idTokenClaims,
                currentStatus: "SIGNED_IN",
            }
        case types.SIGNIN_FAILURE:
            return {
                ...state,
                isSigningIn: false,
                error: action.payload.error,
                currentStatus: "EMPTY_AUTH",
            }  
        case types.ACQUIRE_TOKEN_FAILURE:
            return {
                ...state,
                isAcquiringToken: false,
                error: action.payload.error,
            }
        case types.CHANGE_CURRENT_STATUS:
            return {
                ...state,
                currentStatus: action.payload.status
            }
        case types.GET_USER_PERMISSIONS_REQUEST:
            return {
                ...state,
                isFetchingPermissions: true,
            }
        case types.GET_USER_PERMISSIONS_RECEIVE:
            const pay = action.payload;
            return {
                ...state,
                isFetchingPermissions: false,
                entityManager: action.payload.entityManager,
                isSuperAdmin: action.payload.superAdmin,
                coreFilling: action.payload.coreFilling,
                userType: action.payload.userType
            }
        case types.GET_USER_PERMISSIONS_FAILURE:
            return {
                ...state,
                isFetchingPermissions: false,
                error: action.payload.error,
            }
        default:
            return state
    }
}
