import { combineReducers } from "redux";

import tenants from "./tenants";
import users from "./users";
import mappings from "./mappings";
import entities from "./entities";
import powerbis from "./powerbis";

export default combineReducers({	
	tenants,
	users,
	mappings,
	entities,
	powerbis
});
