// Libraries
import React from "react";
import ReactDOM from "react-dom";

// Components
import { Confirm } from "components";

export const confirm = function(options) {
	const {
        description,
		onAccept: handleAccept,
		onCancel: handleCancel
	} = options;

	const wrapper = document.body.appendChild(
		document.createElement("div"));

	const cleanup = () => {
		ReactDOM.unmountComponentAtNode(wrapper);
		setTimeout(() => {
			wrapper.remove();
		});
	};

	return new Promise((resolve) => {
		const props = {
			...options,
			description,
			onAccept: () => {
				cleanup();

				if (handleAccept) {
					handleAccept();
				}

				resolve(true);
			},
			onCancel: () => {
				cleanup();

				if (handleCancel) {
					handleCancel();
				}

				resolve(false);
			}
		};

		ReactDOM.render(<Confirm { ...props } />, wrapper);
	});
};
