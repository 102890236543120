import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import Card, {
    CardHeader,
    CardContent,
    CardFooter,
    CardDescription,
} from "components/Card";

// Actions
import { getRules } from "actions/validations";

// Assets
import styles from "./styles.module.css";

export default function Rules({
    manageRuleId,
    validate,
    disableRunBtn,
    isXrblValidation,
    setIsXrblValidation
}) {
    const dispatch = useDispatch();
    const rules = useSelector(state => state.validations.rules);
    

    useEffect(() => {
        dispatch(getRules());
    },[])    

    return (
        <Card>
            <CardHeader
                beforeTitle={<strong>Select Rule Sets</strong>}
            />
            <CardContent>
                <CardDescription>
                    {
                        rules.length === 0 && (
                            <div
                                className={ styles.emptyMessage }
                            >
                                No record found
                            </div>
                        )
                    }
                    {
                        rules.length !== 0 && (
                            <>
                                <div className={styles.formControl}>
                                    <input
                                        type='checkbox'
                                        name='isXrblValidation'
                                        id='isXrblValidation'
                                        onClick={ () => setIsXrblValidation(!isXrblValidation) }
                                    />
                                    <label className={styles.label} htmlFor="isXrblValidation">XBRL validations</label>
                                </div>
                                {
                                    rules.map(rule => (
                                        <Checkbox
                                            onChange={ manageRuleId }
                                            key={rule}
                                            value={rule}
                                            label={rule}
                                        />
                                    ))
                                }
                            </>
                        )
                    }
                </CardDescription>
            </CardContent>
            <CardFooter>
                <Button
                    onClick={validate}
                    className={ styles.button }
                    disabled={ disableRunBtn}
                >
                    Initiate validation run
                </Button>
            </CardFooter>
        </Card>
    )
}