import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from 'formik'

// Components
import { Header, Button, FormError } from "components";

// Actions
import * as actionsTenants from "actions/admin/tenants";

// Assets
import styles from "../styles.module.css";

import { validationSchema } from "./validations";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function TenantEdit({
    id
}) {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionsTenants.getTenant(id));
    }, [])

    const { tenant } = useSelector(state => state.admin.tenants);

    const onSubmit = (tenant) => {
        dispatch(actionsTenants.editTenant(tenant));        
    }

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Edit Tenant"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/tenants') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>
                }
            />

            <Formik
                initialValues={tenant}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(formik) => {
                    return (                
                        <Form>
                            <div className={styles.formControl}>
                                <label htmlFor="fname">Name</label>
                                <Field 
                                    type='text'
                                    name='name'
                                    autoComplete="off"
                                />
                                <ErrorMessage 
                                    name='name'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="server">Server</label>
                                <Field
                                    type='text'
                                    name='server'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='server'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="DatabaseName">Database</label>
                                <Field
                                    type='text'
                                    name='databaseName'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='databaseName'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="UserName">Username</label>
                                <Field
                                    type='text'
                                    name='userName'
                                    autoComplete="off"
                                />
                                <ErrorMessage
                                    name='userName'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="password">Password</label>
                                <Field
                                    type='password'
                                    name='password'
                                />
                                <ErrorMessage
                                    name='password'
                                    component={FormError}
                                />
                            </div>
                            <div className={ styles.formFooter }>                            
                                <Button
                                    type='submit'
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    Submit
                                </Button>                                                
                            </div>                       
                        </Form>
                    )
                }}
            </Formik>
        </main>
    )
}