import { combineReducers } from 'redux';

import auth from './auth';
import analytics from './analytics';
import profile from './profile';
import reports from './reports';
import tenants from './tenants';
import entities from './entities';
import logs from './logs';
import validations from './validations';
import corefilings from './corefilings';
import admin from './admin';
import notifications from './notifications';

export default combineReducers({
    auth,
    analytics,
    profile,
    reports,
    tenants,
    entities,
    logs,
    validations,
    corefilings,
    admin,
    notifications
});