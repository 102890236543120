import React, { useState } from 'react';
import classnames from "classnames";

// Components

import styles from "./styles.module.css";

export default function InputFile({
    onFilesSelected: reportFiles,
    classes: {
        rootClassName,
        inputClassName
    } = {},
    ...rest
}) {
    const [id] = useState(Math.random());

    const onChange = ev => {
        const { files } = ev.target;

        if (files.length > 0) {
            reportFiles(files);
        }
    }

    return (
        <label
            className={ classnames(styles.component, rootClassName) }
            htmlFor={`input-file-${id}`}
        >
            <i className={ classnames(styles.icon, "fa fa-cloud-upload") } aria-hidden="true"></i>
            <span>Select Files</span>
            <input
                className={ classnames(styles.input, inputClassName) }
                type="file"
                name="file_sent"
                id={`input-file-${id}`}
                onChange={ onChange }
                { ...rest }
            />
        </label>
    );
}
