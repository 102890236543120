import React from "react";
import styles from "./styles.module.css";

export default function ProgressBar({ value }) {
    return (
        <progress
            className={ styles.component }
            max="100"
            value={ value }
        />
    )
}
