import api from 'config/api';
import download from 'downloadjs';

export const types = {
    GET_LIST_REQUEST: "reports/getListRequest",
    GET_LIST_RECEIVE: "reports/getListReceive",
    GET_LIST_FAILURE: "reports/getListFailure",

    DELETE_ITEM_REQUEST: "reports/deleteItemRequest",
    DELETE_ITEM_SUCCEED: "reports/deleteItemSucceed",
    DELETE_ITEM_FAILURE: "reports/deleteItemFailure",

    CHANGE_SELECTED_PERIOD: "reports/changeSelectedPeriod",
    OPEN_UPLOAD_MODAL: "reports/openUploadModal",
    CLOSE_UPLOAD_MODAL: "reports/closeUploadModal",
    TOGGLE_FILE_HISTORY: "reports/toggleFileHistory",

    DOWNLOAD_ITEM_REQUEST: "reports/downloadItemRequest",
    DOWNLOAD_ITEM_SUCCEED: "reports/downloadItemSucceed",
    DOWNLOAD_ITEM_FAILURE: "reports/downloadItemFailure",

    GENERATE_ITEM_REQUEST: "reports/generateItemRequest",
    GENERATE_ITEM_SUCCEED: "reports/generateItemSucceed",
    GENERATE_ITEM_FAILURE: "reports/generateItemFailure",
}

export const getCatalogRequest = () => ({ type: types.GET_LIST_REQUEST, });

export const openUploadModal = () => ({ type: types.OPEN_UPLOAD_MODAL, });

export const closeUploadModal = () => ({ type: types.CLOSE_UPLOAD_MODAL, });

export const toggleFileHistory = (type, period) => ({
    type: types.TOGGLE_FILE_HISTORY,
    payload: {
        type,
        period
    }
});

export const getCatalogReceive = data => ({
    type: types.GET_LIST_RECEIVE,
    payload: {
        data
    },
});

export const getCatalogFailure = error => ({
    type: types.GET_LIST_FAILURE,
    payload: {
        error
    },
    error: true,
});

export const getReportsCatalog = () => dispatch => {
    dispatch(getCatalogRequest());

    api.get(api.defaults.REPORTS_URL)
        .then((response) => {
            dispatch(getCatalogReceive(response.data));
        })
        .catch((error) => {
            console.log(error.response)
            dispatch(getCatalogFailure(error.response));
        })    
}


export const deleteReportRequest = parentReportId => ({
    type: types.DELETE_ITEM_REQUEST,
    payload: {
        id: parentReportId
    },
});
export const deleteReportSucceed = parentReportId => ({
    type: types.DELETE_ITEM_SUCCEED,
    payload: {
        id: parentReportId
    },
});

export const deleteReportFailure = (parentReportId, error) => ({
    type: types.DELETE_ITEM_FAILURE,
    payload: {
        id: parentReportId,
        error
    },
    error: true,
});


export const deleteReport = (ids) => (dispatch) => {
    const [ parentReportId ] = ids;
    dispatch(deleteReportRequest(parentReportId));

    api.delete(api.defaults.DELETE_URL, {
            data: ids
        })
        .then(() => {
            dispatch(deleteReportSucceed(parentReportId));
            dispatch(getReportsCatalog());
        })
        .catch(error => {
            dispatch(deleteReportFailure(parentReportId, error.response));
        })
}

export const changeSelectedPeriod = period => ({
    type: types.CHANGE_SELECTED_PERIOD,
    payload: {
        period
    }
});

export const downloadReportRequest = id => ({
    type: types.DOWNLOAD_ITEM_REQUEST,
    payload: {
        id
    },
});
export const downloadReportSucceed = id => ({
    type: types.DOWNLOAD_ITEM_SUCCEED,
    payload: {
        id
    },
});
export const downloadReportFailure = (id, error) => ({
    type: types.DOWNLOAD_ITEM_FAILURE,
    payload: {
        id,
        error
    },
    error: true,
});
export const downloadReport = (id) => (dispatch) => {
    dispatch(downloadReportRequest(id));
    api.get(`${api.defaults.DOWNLOAD_URL}/${id}`)
        .then((res) => {
            const { filename, filetype, content } = res.data;
            const data = `data:file/${filetype};base64,${content}`
            const mimeType = `file/${filetype}`;
            download(data, filename, mimeType);
            dispatch(downloadReportSucceed(id));
        })
        .catch(error => {
            dispatch(downloadReportFailure(id, error.response));
        })
}

export const generateTemplate = schemaUrl => dispatch => {

    return;

    const headers = {
        'Content-Type': 'application/hal+json'
    }
    api.post(api.defaults.GENERATE_TEMPLATE, schemaUrl, {
        headers: headers
    }).then((response) => {

    })
}

export const generateReportRequest = id => ({
    type: types.GENERATE_ITEM_REQUEST,
    payload: {
        id
    },
});
export const generateReportSucceed = id => ({
    type: types.GENERATE_ITEM_SUCCEED,
    payload: {
        id
    },
});
export const generateReportFailure = (id, error) => ({
    type: types.GENERATE_ITEM_FAILURE,
    payload: {
        id,
        error
    },
    error: true,
});

export const generateReport = (id, filetype) => dispatch => {
    dispatch(generateReportRequest(id));
    
    api.post(`${api.defaults.GENERATE_REPORT}`, {
        parentReportId: id,
        filetype
    })
    .then((res) => {
        dispatch(generateReportSucceed(id));
        dispatch(getReportsCatalog());
    })
    .catch(error => {
        dispatch(generateReportFailure(id, error.response));
    })
}

export const checkReportSubmissionStatus = (submissionId, submissionIds) => (dispatch) => {
    api.get(`${api.defaults.CHECK_REPORT_SUBMISSION_STATUS}/${submissionId}`)
        .then((res) => {
            if(res.data) {                
                const reportSubmissionIds = submissionIds.filter(subId => subId !== submissionId)
                sessionStorage.setItem("reportSubmissionIds", JSON.stringify(reportSubmissionIds));
                dispatch(getReportsCatalog());
            }
        });
}