import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import * as templatesActions from "actions/templates";

// Components
import {
    Card,
    Modal,
    Button,
} from 'components';
import { CardHeader, CardContent, CardDescription, CardFooter } from "components/Card";

// Assets
import styles from "./styles.module.css";

function ModalDownloadTemplate() {
    const dispatch = useDispatch();

    const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] = useState(true)
    const [isDownloading, setIsDownloading] = useState(false)
    const [selectedPipeline, setSelectedPipeline] = useState('')

    useEffect(() => {
        dispatch(templatesActions.getPipelines());
    }, [])

    const pipelines = useSelector(state =>
        state.reports.templates.pipelines
    );
   
    const closeDownloadTemplateModal = () => {
        dispatch(templatesActions.closeDownloadTemplateModal());
    };

    const changePipeline = (e) => {
        const pipeline = e.target.value;
        setSelectedPipeline(pipeline)
        setIsDownloadButtonDisabled(false)
    }

    const downloadTemplate = () => {
        dispatch(templatesActions.downloadTemplate(selectedPipeline));
        setIsDownloading(true)
        setIsDownloadButtonDisabled(true)
    }
    
    return (
        <Modal onClose={ closeDownloadTemplateModal }>
            <Card
                className={ styles.card }
                element="section"
            >
                <CardHeader
                    heading="h2"
                    title='Download Templates'
                    rightChildren={
                        <button
                            className={ styles.close }
                            onClick={ closeDownloadTemplateModal }
                            aria-label="Close upload report form"
                        >
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    }
                />
                <CardContent>
                    <CardDescription>
                        {
                            !isDownloading && (
                                <>
                                    Please select the desired taxonomy.
                                    <select
                                        onChange={ changePipeline }
                                        className={ styles.select }
                                    >
                                        <option>
                                            Select taxonomy
                                        </option>
                                        {
                                            pipelines.map(({ name }) => (
                                                <option value={ name } key={ name }>
                                                    { name }
                                                </option>
                                            ))
                                        }
                                    </select>
                                </>
                            )
                        }
                        {
                            isDownloading && (
                                <strong className={styles.downloadSuccess}>
                                    Your templates will be downloaded shortly
                                </strong>
                            )
                        }
                    </CardDescription>                  
                </CardContent>
                <CardFooter>
                    <Button
                        disabled={ isDownloadButtonDisabled }
                        onClick={ downloadTemplate }
                    >
                        Download
                    </Button>
                </CardFooter>
            </Card>
        </Modal>
    )
}

export default function Relay() {
    const isDownloadTemplateModalOpen = useSelector(state =>
        state.reports.templates.isDownloadTemplateModalOpen
    );

    if (isDownloadTemplateModalOpen) {
        return (
            <ModalDownloadTemplate />
        )
    }
    return null;
}