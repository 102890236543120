import React from 'react';

// Components
import {
    Button,
    Modal,
} from 'components';
import Card, { CardHeader, CardContent, CardFooter, CardDescription } from "components/Card";

import styles from "./styles.module.css";

export default function Confirm({
    title,
    description,
    element,
    isAcceptDisabled = false,
    acceptText = "Confirm",
    cancelText = "Cancel",
    onClose: closeModal,
    onAccept,
    onCancel,
}) {

    return (
        <Modal
            onClose={ closeModal }
            aria-labelledby="modal-title"
			aria-describedby="modal-description"
        >
            <Card
                className={ styles.card }
                element="section"
            >
                <CardHeader
                    headingId="modal-title"
                    heading="h2"
                    title={ title }
                />
                <CardContent>
                    <CardDescription
						descriptionId="modal-description"
                    >
                        { description }
                    </CardDescription>
                    { element }
                </CardContent>
                <CardFooter
                    className={ styles.footer }
                >
                    <Button
                        variant="secondary"
                        className={ styles.button }
                        onClick={ onCancel }
                    >
                        { cancelText }
                    </Button> 
                    <Button
                        variant="primary"
                        className={ styles.button }
                        onClick={ onAccept }
                        disabled={ isAcceptDisabled }
                    >
                        { acceptText }
                    </Button>                       
                </CardFooter>
            </Card>
        </Modal>
    )
}
