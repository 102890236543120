import React from "react";
import { useSelector } from 'react-redux';
import { Link } from "@reach/router";
import classnames from "classnames";
import styles from "./styles.module.css";

const NavLink = props => (
    <Link
        {...props}
        getProps={({ isCurrent }) => {
            // the object returned here is passed to the
            // anchor element's props
            return {
                className: classnames(styles.link, { [styles.active]: isCurrent })
            };
        }}
    />
);

export default function Navigation() {
    const { userType, entityManager, coreFilling } = useSelector(state => state.auth);
    const selectedEntityName = useSelector(state => state.entities.selected.name);
    return (
        <>
            <nav className={ styles.component }>
                <ul className={ styles.list }>
                    {
                        selectedEntityName && (
                            <>
                                {
                                    (userType === 'default' || userType === 'all') && (
                                        <>
                                            <li>
                                                <NavLink to="/" exact>Home</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/reports">Reports</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/validations">Validations</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/analytics">Analytics</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/logs">Logs</NavLink>
                                            </li>
                                        </>
                                    )
                                }
                                {
                                    coreFilling && (
                                        <li>
                                            <NavLink to="/corefilings">CoreFiling</NavLink>
                                        </li>
                                    )
                                }

                                {
                                    entityManager && (
                                        <li>
                                            <NavLink to="/admin/entities">Entity Manager</NavLink>
                                        </li>
                                    )
                                }
                            </>
                        )
                    }
                </ul>
            </nav>
        </>
    );
}
