import React from 'react';

// Components
import { Header } from "components";

// Own Components
import { Filters } from "./components";

// Assets
import styles from "./styles.module.css";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function ReportHeaderContainer({ title = "" }) {
    return (           
        <Header
            heading="h2"
            title={ title }
            classes={ HEADER_CLASSES }
            rightChildren={ <Filters /> }
        />
    )
}
