import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Components
import { Header, Button } from "components";

// Actions
import * as actionsEntities from "actions/admin/entities";

// Assets
import styles from "../styles.module.css";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function EntityView({id}) {

    const dispatch = useDispatch();

    const { tenantId } = useSelector(state => state.admin.tenants);
    const {
        name,
        reportingLevel,
        xbrlReportingLevel,
        xbrlIdentifier
    } = useSelector(state => state.admin.entities.entity);

    useEffect(() => {
        dispatch(actionsEntities.getEntity(tenantId, id));
    }, [])

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="View Entity"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/entities') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>
                }
            />

            <form className={ styles.form }>
                <div className={styles.formControl}>
                    <label htmlFor="name">Name</label>
                    <input 
                        type='text'
                        id='name'
                        value={name}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="reportingLevel">Reporting Level</label>
                    <input
                        type='text'
                        id='reportingLevel'
                        value={reportingLevel}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="xbrlReportingLevel">Xbrl Reporting Level</label>
                    <input
                        type='text'
                        id='xbrlReportingLevel'
                        value={xbrlReportingLevel}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="xbrlIdentifier">Xbrl Identifier</label>
                    <input
                        type='text'
                        id='xbrlIdentifier'
                        value={xbrlIdentifier}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
            </form>
        </main>
    )
}