import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";
import { Formik, Form, Field, ErrorMessage } from 'formik'

// Components
import { Header, Button, FormError } from "components";

// Actions
import * as actionsMappings from "actions/admin/mappings";
import * as actionsTenants from "actions/admin/tenants";
import * as actionsUsers from "actions/admin/users";

// Assets
import styles from "../styles.module.css";

import { validationSchema } from "./validations";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

const initialValues = {
    tenantId: "",
    userObjectId: ""
}

export default function MappingCreate() {

    const dispatch = useDispatch();
    const { users } = useSelector(state => state.admin.users);
    const { tenants } = useSelector(state => state.admin.tenants);

    const onSubmit = (mapping) => {
        mapping['tenantId'] = parseInt(mapping['tenantId'])
        dispatch(actionsMappings.createMapping(mapping));
    }

    useEffect(() => {
        dispatch(actionsTenants.getTenants());
        dispatch(actionsUsers.getUsers());
    }, [])

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Create a new mapping"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/mappings') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>
                }
            />

            <Formik
                initialValues={ initialValues }
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(formik) => {
                    return (                
                        <Form>
                            <div className={styles.formControl}>
                                <label htmlFor="objectId">Tenant</label>
                                <Field
                                    as="select"
                                    name="tenantId"
                                    className={ styles.select }
                                >
                                    <option value=""> Select a tenant </option>                     
                                    {
                                        tenants.map(({ id, name }) => (
                                            <option
                                                key={ id }
                                                value={ id }
                                            >
                                                { name }
                                            </option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage
                                    name='tenantId'
                                    component={FormError}
                                />
                            </div>
                            <div className={styles.formControl}>
                                <label htmlFor="objectId">User</label>
                                <Field
                                    as="select"
                                    name="userObjectId"
                                    className={ styles.select }
                                >
                                    <option value=""> Select a user </option>                      
                                    {
                                        users.map(({ objectId, email }) => (
                                            <option
                                                key={ objectId }
                                                value={ objectId }
                                            >
                                                { email }
                                            </option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage
                                    name='userObjectId'
                                    component={FormError}
                                />
                            </div>
                            
                            <div className={ styles.formFooter }>                            
                                <Button
                                    type='submit'
                                    disabled={!(formik.dirty && formik.isValid)}
                                >
                                    Submit
                                </Button>                                                
                            </div>                   
                        </Form>
                    )
                }}
            </Formik>
        </main>
    )
}