import React from 'react';
import { navigate } from "@reach/router";
import { useDispatch } from 'react-redux';

// Utils
import { confirm } from "utils/confirm";

// Actions
import * as actionsTenants from "actions/admin/tenants";

// Components
import { More } from "components/Table";

export default function Actions({
    id,
    name,
    isDeleting
}) {
    const dispatch = useDispatch();

    const viewTenant = () => {
        navigate(`tenants/view/${id}`)
    }

    const editTenant = () => {
        navigate(`tenants/edit/${id}`)     
    }    

    const deleteTenant = () => {
        confirm({
            title: "Are you sure?",
            description: `Are you sure you want to delete the following tenant: ${name}?`,
        })
        .then((shouldProceed) => {
            if (shouldProceed) {
                dispatch(actionsTenants.deleteTenant(id));
            }
        })
    }

    if (isDeleting) {
        return (
            <span style={ { fontSize: ".8rem" } }>Deleting</span>
        );
    }

    return (
        <More
            options={ [
                {
                    type: "button",
                    action: viewTenant,
                    text: "View",
                    icon: (<i className="fa fa-info-circle"/>)
                } ,
                {
                    type: "button",
                    action: editTenant,
                    text: "Edit",
                    icon: (<i className="fa fa-edit"/>)
                } ,
                {
                    type: "button",
                    action: deleteTenant,
                    text: `Delete`,
                    icon: (<i className="fa fa-trash" />)
                }
            ] }
        />
    )
}
