import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "@reach/router";
import { Badge } from 'react-bootstrap';
import formatDate, { DEFAULT_FORMAT } from "utils/formatDate";

// Actions
import * as validationActions from "actions/validations";

// Components
import { Table, Header } from "components";

// Own Components
import { Filters, ModalDetails } from "./components";

// Assets
import styles from "./styles.module.css";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

const TIME = "HH:MM";

const getReportTypes = (reports) => {
    let reportTypeArray = reports.split('|').map(item => {
        let [reportType] = item.split(" (");
        return reportType.replace(/\s/g, '');
    })

    let distinctReportTypes = [...new Set(reportTypeArray)].join(" ")

    return distinctReportTypes;
}

export default function Results() {
    const dispatch = useDispatch();    
    const { isFetchingResults, list } = useSelector(state => state.validations);

    const getResult = (result) => {        
        dispatch(validationActions.getResult(result));
    }

    useEffect(() => {
        dispatch(validationActions.getResults());
    }, []);    

    return (
        <>
            <Header
                heading="h2"
                title="Validation Results"
                classes={ HEADER_CLASSES }
                rightChildren={ <Filters /> }
            />
            <ModalDetails />
            <Table
                emptyMessage="No reports found"
                isLoadingData={ isFetchingResults }
                minWidth={ 400 }
                widths={ [350, 250, 250, 150, 100] }
                headItems={ ["Report type", "User", "Date & Time", "Status", ""] }
                bodyItems={
                    list.map(({ id, user, runDate, reports, actionStatus }) => ([
                        getReportTypes(reports),
                        user,
                        formatDate(runDate, `${DEFAULT_FORMAT} ${TIME}`),
                        <>
                            {
                                !actionStatus.finished ?
                                    <Badge variant="secondary">In-Progress</Badge>
                                    :
                                    (
                                        actionStatus.withErrors ?
                                            <Badge variant="danger">Error</Badge>
                                            :
                                            <Badge variant="success">Success</Badge>
                                    )

                            }
                        </>,
                        <Link to="" onClick={ () => getResult(id) }>Details</Link>
                    ]))
                }
            />            
        </>
    )
}