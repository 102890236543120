import React, { useEffect } from 'react';
import { Router } from "@reach/router";
import { useDispatch, useSelector } from 'react-redux';

import * as actionsAuth from "actions/auth";
import * as actionsTenants from "actions/tenants";
import * as actionsEntities from "actions/entities";

// Components
import {
  Footer,
  Navigation,
  Navbar,
  StatusCard,
} from "components";

// Routes
import {
  Home,
  NotFound,
  Reports,
  Logs,
  LogDetails,
  Validations,
  Corefilings,
  Tenants,
  TenantEdit,
  TenantView,
  TenantCreate,
  Users,
  UserEdit,
  UserView,
  UserCreate,
  Mappings,
  MappingCreate,
  Entities,
  EntityEdit,
  EntityView,
  EntityCreate,
  Powerbis,
  PowerbiEdit,
  PowerbiView,
  PowerbiCreate,
  Analytics
} from "routes";

// Assets
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import './index.css';
import 'routes/Logs/Logs.css';

const App = () => {
  const dispatch = useDispatch();

  const { isAuthenticated, userType, entityManager, coreFilling } = useSelector(state => state.auth);
  const tenantsList = useSelector(state => state.tenants.list);
  const entitiesList = useSelector(state => state.entities.list);

  useEffect(() => {
    dispatch(actionsAuth.acquireToken());
  }, []);

  useEffect(() => {
    if (isAuthenticated && sessionStorage.getItem("token")) {
      dispatch(actionsTenants.getTenants());
      dispatch(actionsAuth.getUserPermissions());

      if(sessionStorage.getItem('entityId')) {
        dispatch(actionsEntities.getEntities());
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && tenantsList.length) {
      const ssTenantId = sessionStorage.getItem("tenantId");
      if (tenantsList.length && ssTenantId) {
        const tenant = tenantsList.find(({ id }) => id === Number(ssTenantId));
        dispatch(actionsTenants.selectTenant(tenant));
      }
    }
  }, [isAuthenticated, tenantsList]);

  useEffect(() => {
    if (isAuthenticated && entitiesList.length) {
      const ssEntityId = sessionStorage.getItem("entityId");
      if (entitiesList.length && ssEntityId) {
        const entity = entitiesList.find(({ id }) => id === ssEntityId);
        dispatch(actionsEntities.selectEntity(entity));
      }
    }
  }, [isAuthenticated, entitiesList]);

  if (!isAuthenticated) {
    return (
      <>
        <Navbar />
        <StatusCard />
      </>      
    )
  }

  return (
    <>
      <Navbar />
      <Navigation/>
      <Router>
        {
          (userType === 'default' || userType === 'all') && (
            <>
              <Home exact path="/" />
              <Logs exact path="logs" />
              <LogDetails exact path="logs/:category" />
              <Reports exact path="reports" />
              <Validations exact path="validations" />
              <Analytics exact path="analytics" />
              <NotFound default/>
            </>
          )
        }

        {
          (userType === 'admin'  || userType === 'all') && (
            <>
              <Tenants exact path="/" />
              <Tenants path="admin/tenants" />
              <TenantEdit path="admin/tenants/edit/:id" />
              <TenantView path="admin/tenants/view/:id" />
              <TenantCreate path="admin/tenants/create" />
              <Users path="admin/users" />
              <UserEdit path="admin/users/edit/:id" />
              <UserView path="admin/users/view/:id" />
              <UserCreate path="admin/users/create" />
              <Powerbis path="admin/powerbis" />
              <PowerbiEdit path="admin/powerbis/edit/:id" />
              <PowerbiView path="admin/powerbis/view/:id" />
              <PowerbiCreate path="admin/powerbis/create" />
              <Mappings path="admin/mappings" />
              <MappingCreate path="admin/mappings/create" />
              <NotFound default/>
            </>
          )
        }

        {
          entityManager && (
            <>
              <Entities path="admin/entities" />
              <EntityEdit path="admin/entities/edit/:id" />
              <EntityView path="admin/entities/view/:id" />
              <EntityCreate path="admin/entities/create" />
            </>
          )
        }

        {
          coreFilling && (
            <Corefilings path="corefilings" />
          )
        }

        <NotFound path="notfound"/>
      </Router>
      <Footer />
    </>
  )
}

export default App;
