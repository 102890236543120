import React, { useEffect, useRef, useState } from "react";
import { useReport } from "powerbi-report-component";
import { useSelector, useDispatch } from "react-redux";
import * as analyticsActions from "actions/analytics";

import styles from "./styles.module.css";

export default function Analytics() {
	const dispatch = useDispatch();

	const isFetching = useSelector(state => state.analytics.isFetching);
	const embedConfig = useSelector(state => state.analytics.item);

	const [noAnalytics, setNoAnalytics] = useState(false);
	const reportRef = useRef(null);
	const [, setEmbed] = useReport();

	useEffect(() => {
		if (!embedConfig) {
			setNoAnalytics(true);
			return;
		}

		const { EmbedReport, EmbedToken } = embedConfig;

		if (!EmbedToken || !EmbedReport || !Array.isArray(EmbedReport)) {
			setNoAnalytics(true);
			return;
		}

		const [ firstReport ] = EmbedReport;

		if (!firstReport) {
			setNoAnalytics(true);
			return;
		}

		const id = firstReport.ReportId;
		const url = firstReport.EmbedUrl;
		const token = EmbedToken.Token;

		if (id && token && url) {
			const powerBiReportConfig = {
				embedType: "report",
				tokenType: "Embed",
				accessToken: token,
				embedUrl: url,
				embedId: id,
				reportMode: "View", // "Edit"
				permissions: "View", // "All" (when using "Edit" mode)
				extraSettings: {
					filterPaneEnabled: false,
					navContentPaneEnabled: true,
				}
			};

			setEmbed(reportRef, powerBiReportConfig);
			setNoAnalytics(false);
		} else {
			setNoAnalytics(true);
		}
	}, [embedConfig]);

	useEffect(() => {
		dispatch(analyticsActions.getEmbedConfig());
	}, []);

	return (
		<div className={ styles.view }>
			{
				isFetching && (
					<p>Getting reports</p>
				)
			}
			{
				!isFetching && noAnalytics && (
					<p>No analytics available</p>
				)
			}
			<div className={ styles.report } ref={ reportRef } />
		</div>
	);
}
