import api from 'config/api';
// Types
export const types = {
    GET_ACTIONS_REQUEST: "actions/getActionsRequest",
    GET_ACTIONS_SUCCESS: "actions/getActionsSuccess",
    GET_ACTIONS_FAILURE: "actions/getActionsFailure",
    GET_ACTIONS_IN_CATEGORY: "actions/getActionsByCategory",

    GET_LOGS_REQUEST: "actions/getLogsRequest",
    GET_LOGS_SUCCESS: "actions/getLogsSuccess",
    GET_LOGS_FAILURE: "actions/getLogsFailure",

    GET_ACTIONS_IN_CATEGORY: "actions/getActionsByCategory",
    GET_CURRENT_ACTIONS: "actions/getActionsInCurrentPage"
}
// Actions
export const getActionsRequest = () => ({ type: types.GET_ACTIONS_REQUEST, });
export const getActionsSuccess = actions => ({
    type: types.GET_ACTIONS_SUCCESS,
    payload: {
        actions,
    }
});
export const getActionsFailure = error => ({
    type: types.GET_ACTIONS_FAILURE,
    payload: {
        error,
    }
});

export const getLogsRequest = () => ({ type: types.GET_LOGS_REQUEST, });
export const getLogsSuccess = logs => ({
    type: types.GET_LOGS_SUCCESS,
    payload: {
        logs,
    }
});
export const getLogsFailure = error => ({
    type: types.GET_LOGS_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getActions = () => (dispatch) => {
    dispatch(getActionsRequest());
    api.get(api.defaults.GET_ACTIONS)
        .then((response) => {
            const actions = response.data;
            dispatch(getActionsSuccess(actions));
        })
        .catch(error => {
            dispatch(getActionsFailure(error.message));
        })
}

export const getLogs = (actionId) => (dispatch) => {
    dispatch(getLogsRequest());
    api.get(`${api.defaults.GET_LOGS}/${actionId}`)
        .then((response) => {
            const logs = response.data;
            dispatch(getLogsSuccess(logs));
        })
        .catch(error => {
            dispatch(getLogsFailure(error.message));
        })
}

export const getActionsByCategory = category => ({
    type: types.GET_ACTIONS_IN_CATEGORY,
    payload: {
        category,
    }
});

export const getActionsInCurrentPage = pageNumber => ({
    type: types.GET_CURRENT_ACTIONS,
    payload: {
        pageNumber,
    }
});