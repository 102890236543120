import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from 'reducers';

// If you have the Redux Dev Tools extension installed on your browser, this enables it
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function configureStore() {
    return createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    );
}

const store = configureStore();

export default store;
