import { types } from "actions/templates";
const INITIAL_STATE = {
    isDownloadingTemplates: false,
    isFetchingPipelines: false,
    pipelines: [],
    isDownloadTemplateModalOpen: false,
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.DOWNLOAD_TEMPLATES_REQUEST:
            return {
                ...state,
                isDownloadingTemplates: true
            }
        case types.DOWNLOAD_TEMPLATES_SUCCEED:
            return {
                ...state,
                isDownloadingTemplates: false
            }
        case types.DOWNLOAD_TEMPLATES_FAILURE:
            return {
                ...state,
                isDownloadingTemplates: false
            }
        case types.OPEN_DOWNLOAD_TEMPLATE_MODAL:
            return {
                ...state,
                isDownloadTemplateModalOpen: true,
            }
        case types.CLOSE_DOWNLOAD_TEMPLATE_MODAL:
            return {
                ...state,
                isDownloadTemplateModalOpen: false,
            }
        case types.GET_PIPELINES_REQUEST:
            return {
                ...state,
                isFetchingPipelines: true
            }
        case types.GET_PIPELINES_SUCCEED:
            return {
                ...state,
                pipelines: action.payload.pipelines,
                isFetchingPipelines: false
            }
        case types.GET_PIPELINES_FAILURE:
            return {
                ...state,
                isFetchingPipelines: false
            }         
        default:
            return state;
    }
}