import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';

// Components
import Table from "components/Table";

// Own Components
import { ModalVersions, MoreActions } from "./components";

// Assets
import styles from "./styles.module.css";

export default function ReportsTable() {

    const {
            isFetching,
            isDownloading,
            isGeneratingReport,
            list,
            idsBeingDeleted
        } = useSelector(state => state.reports.catalog);

    return (
        <>
            <ModalVersions />    
            <Table
                emptyMessage="Please upload a report"
                isLoadingData={ isFetching || isDownloading || isGeneratingReport }
                widths={ [400, 300, 100, 150, 100] }
                headItems={ ["File name", "Report type", "Version", "Status", ""] }
                bodyItems={
                    list.map(({ filetype, type, version, id, filename, content, systemGerenatedReport, period, actionStatus }) => ([
                        filename,
                        type,
                        version,
                        <>
                            {
                                !actionStatus.finished ?
                                    <Badge variant="secondary">In-Progress</Badge>
                                    :
                                    (
                                        actionStatus.withErrors ?
                                            <Badge variant="danger">Error</Badge>
                                            :
                                            <Badge variant="success">Success</Badge>
                                    )

                            }
                        </>,
                        <MoreActions
                            id={ id }
                            systemGerenatedReportId={ systemGerenatedReport ? systemGerenatedReport.id : null }
                            filename={ filename }
                            content={ content }
                            filetype={ filetype }
                            type={ type }
                            period={ period }
                            hideVersionButton= { version === 1 }
                            isDeleting={ idsBeingDeleted.includes(id) }
                        />
                    ]))
                }
            />
        </>
    )
}
