import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Components
import { Header, Button } from "components";

// Actions
import * as actionsPowerbis from "actions/admin/powerbis";

// Assets
import styles from "../styles.module.css";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function PowerbiView({id}) {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionsPowerbis.getPowerbi(id));
    }, [])

    const {
        reportId,
        clientId,
        workspaceId,
        tenantId,
        entityId,
        clientSecret
    } = useSelector(state => state.admin.powerbis.powerbi);


    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="View Power BI"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/powerbis') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>                  
                }
            />

            <form className={ styles.form }>
                <div className={styles.formControl}>
                    <label htmlFor="reportId">Report Id</label>
                    <input 
                        type='text'
                        value={reportId}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="clientId">Client Id</label>
                    <input
                        type='text'
                        value={clientId}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="workspaceId">Workspace Id</label>
                    <input
                        type='text'
                        value={workspaceId}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="tenantId">Tenant Id</label>
                    <input
                        type='text'
                        value={tenantId}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="tenantId">Entity Id</label>
                    <input
                        type='text'
                        value={entityId}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="clientSecret">Client Secret</label>
                    <input
                        type='text'
                        value={clientSecret}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
            </form>
        </main>
    )
}