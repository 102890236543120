import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Own Components
import { MoreActions } from "./components";

// Components
import { Header, Button, Table } from "components";

// Assets
import styles from "./styles.module.css";

// Actions
import * as actionsUsers from "actions/admin/users";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function Users() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionsUsers.getUsers());
    }, [])

    const {
        isFetching,
        users,
        idsBeingDeleted
    } = useSelector(state => state.admin.users);

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Users"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/users/create') } }
                        className={ styles.button }
                    >
                        Create a new User
                    </Button>                  
                }
            />

            <Table
                emptyMessage="No users found"
                isLoadingData={ isFetching }
                widths={ [150, 150, 200, 200, 200] }
                headItems={ ["Firstname", "Lastname", "Email", "User Type", ""] }
                bodyItems={
                    users.map(({
                        objectId,
                        firstname,
                        lastname,
                        email,
                        userType
                    }) => ([
                        firstname,
                        lastname,
                        email,
                        userType.toUpperCase(),
                        <MoreActions
                            objectId={objectId}
                            email={email}
                            isDeleting={ idsBeingDeleted.includes(objectId) }
                        />
                    ]))
                }
            />
        </main>
    )
}