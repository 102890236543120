import React, { useState } from "react";
import { Link } from "@reach/router";

import styles from "./styles.module.css";

export default function More({
    options = []
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [timeoutId, setTimeoutId] = useState();

    const handleFocus = () => {
        clearTimeout(timeoutId)
    }

    const handleBlur = () => {
        setTimeoutId(setTimeout(() => setIsOpen(false)))
    }

    const toggleOptions = () => {
        setIsOpen(prev => !prev)
    }

    const buildIcon = (icon) => (
        <span className={ styles.icon }>{ icon }</span>
    )
    const buildText = (text) => (
        <span
            title={ text }
            className={ styles.text }
        >
            { text }
        </span>
    )
    const handleClick = (e, action) => {
        setIsOpen(false)
        action()
    };

    return (
        <div
            className={ styles.more }
            onFocus={ handleFocus }
            onBlur={ handleBlur }
        >
            <button
                className={ styles.trigger }
                onClick={ toggleOptions }
            >
                <div className={ styles.circle }/>
                <div className={ styles.circle }/>
                <div className={ styles.circle }/>
            </button>
            {
                isOpen && (
                    <div className={ styles.options }>
                        {
                            options.map(option => {
                                const { type, text, action, icon, } = option;

                                if (type === "button") {
                                    return (
                                        <button
                                            key={ text }
                                            className={ styles.option }
                                            onClick={ e => handleClick(e, action) }
                                        >
                                            { icon && buildIcon(icon) }
                                            { buildText(text) }
                                        </button>
                                    )
                                } 
                                if (type === "link") {
                                    return (
                                        <Link
                                            key={ text }
                                            className={ styles.option }
                                            to={ action }
                                        >
                                            { icon && buildIcon(icon) }
                                            { buildText(text) }
                                        </Link>                                    
                                    )
                                }

                                return null;
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}