import React from 'react';
import { navigate } from "@reach/router";
import { useDispatch } from 'react-redux';

// Utils
import { confirm } from "utils/confirm";

// Actions
import * as actionsPowerbis from "actions/admin/powerbis";

// Components
import { More } from "components/Table";

export default function Actions({
    id,
    isDeleting
}) {
    const dispatch = useDispatch();

    const viewPowerbi = () => {
        navigate(`powerbis/view/${id}`)
    }

    const editPowerbi = () => {
        navigate(`powerbis/edit/${id}`)     
    }    

    const deletePowerbi = () => {
        confirm({
            title: "Are you sure?",
            description: `Are you sure you want to delete?`,
        })
        .then((shouldProceed) => {
            if (shouldProceed) {
                dispatch(actionsPowerbis.deletePowerbi(id));
            }
        })
    }

    if (isDeleting) {
        return (
            <span style={ { fontSize: ".8rem" } }>Deleting</span>
        );
    }

    return (
        <More
            options={ [
                {
                    type: "button",
                    action: viewPowerbi,
                    text: "View",
                    icon: (<i className="fa fa-info-circle"/>)
                } ,
                {
                    type: "button",
                    action: editPowerbi,
                    text: "Edit",
                    icon: (<i className="fa fa-edit"/>)
                } ,
                {
                    type: "button",
                    action: deletePowerbi,
                    text: `Delete`,
                    icon: (<i className="fa fa-trash" />)
                }
            ] }
        />
    )
}
