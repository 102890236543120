import { types } from "actions/entities";
const INITIAL_STATE = {
    isFetching: false,
    isSelecting: false,
    list: [],
    selected: {},
    error: undefined,
    errorSelecting: undefined,
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {            
        case types.SELECT_ENTITY:
            if (!action.payload.entity) {
                sessionStorage.removeItem('entityId');

                return {
                    ...state,
                    selected: {}
                }
            }

            sessionStorage.setItem('entityId', action.payload.entity.id);
            return {
                ...state,
                selected: action.payload.entity
            }
        case types.GET_LIST_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_LIST_RECEIVE:
            return {
                ...state,
                isFetching: false,
                list: action.payload.list
            }
        case types.GET_LIST_FAILURE:
            return {
                ...state,
                isFetching: false,
                list: [],
                error: action.payload.error,
            }
        default:
            return state
    }
}