import { types } from "actions/profile";

const INITIAL_STATE = {
    isFetching: false,
    error: undefined,
    id: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_PROFILE_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }            
        case types.GET_PROFILE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                // id: action.payload.profile.id,
            };
        case types.GET_PROFILE_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            }
        default:
            return state;
    }
}