import { types } from "actions/admin/users";

const INITIAL_STATE = {
    isFetching: false,
    isCreating: false,
    users: [],
    user: {},
    idsBeingDeleted: [],
    error: undefined,
    errorSelecting: undefined,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_USERS_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_USERS_RECEIVE:
            return {
                ...state,
                isFetching: false,
                user: {},
                users: action.payload.users
            }
        case types.GET_USERS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            }
        case types.CREATE_USER_REQUEST:
            return {
                ...state,
                isCreating: true,
                error: undefined,
            }
        case types.CREATE_USER_RECEIVE:
            return {
                ...state,
                isCreating: false
            }
        case types.CREATE_USER_FAILURE:
            return {
                ...state,
                isCreating: false,
                error: action.payload.error,
            }
        case types.DELETE_USER_REQUEST:
            return {
                ...state,
                idsBeingDeleted: [...state.idsBeingDeleted, action.payload.id],
                error: undefined,
            }
        case types.DELETE_USER_RECEIVE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id)
            }
        case types.DELETE_USER_FAILURE:
            return {
                ...state,
                idsBeingDeleted: state.idsBeingDeleted.filter(id => id !== action.payload.id),
                error: action.payload.error,
            }
        case types.EDIT_USER_REQUEST:
            return {
                ...state,
                isEditing: true,
                error: undefined,
            }
        case types.EDIT_USER_RECEIVE:
            return {
                ...state,
                isEditing: false,
            }
        case types.EDIT_USER_FAILURE:
            return {
                ...state,
                isEditing: false,
                error: action.payload.error,
            }
        case types.GET_USER_REQUEST:
            return {
                ...state,
                isFetching: true,
                error: undefined,
            }
        case types.GET_USER_RECEIVE:
            return {
                ...state,
                isFetching: false,
                user: action.payload.user
            }
        case types.GET_USER_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
        }
        default:
            return state
    }
}
