import api from 'config/api';
import { navigate } from "@reach/router"

// Types
export const types = {
    GET_TENANTS_REQUEST: "tenants/getTenantsRequest",
    GET_TENANTS_RECEIVE: "tenants/getTenantsReceive",
    GET_TENANTS_FAILURE: "tenants/getTenantsFailure",

    GET_TENANT_REQUEST: "tenants/getTenantRequest",
    GET_TENANT_RECEIVE: "tenants/getTenantReceive",
    GET_TENANT_FAILURE: "tenants/getTenantFailure",

    CREATE_TENANT_REQUEST: "tenants/createTenantRequest",
    CREATE_TENANT_RECEIVE: "tenants/createTenantReceive",
    CREATE_TENANT_FAILURE: "tenants/createTenantFailure",

    DELETE_TENANT_REQUEST: "tenants/deleteTenantRequest",
    DELETE_TENANT_RECEIVE: "tenants/deleteTenantReceive",
    DELETE_TENANT_FAILURE: "tenants/deleteTenantFailure",

    EDIT_TENANT_REQUEST: "tenants/editTenantRequest",
    EDIT_TENANT_RECEIVE: "tenants/editTenantReceive",
    EDIT_TENANT_FAILURE: "tenants/editTenantFailure",

    SELECT_TENANT_ID: "tenants/selectTenantId",
}

// Actions
export const selectTenantId = tenantId => ({
    type: types.SELECT_TENANT_ID,
    payload: {
        tenantId,
    }
});

export const getTenantsRequest = () => ({ type: types.GET_TENANTS_REQUEST, });

export const getTenantsReceive = tenants => ({
    type: types.GET_TENANTS_RECEIVE,
    payload: {
        tenants,
    }
});

export const getTenantsFailure = error => ({
    type: types.GET_TENANTS_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getTenants = () => (dispatch) => {
    dispatch(getTenantsRequest());

    api.get(api.defaults.ADMIN_TENANT)
        .then((response) => {
            const tenants = response.data;
            dispatch(getTenantsReceive(tenants));
        })
        .catch(error => {
            dispatch(getTenantsFailure(error.message));
        })
}


export const createTenantRequest = () => ({ type: types.CREATE_TENANT_REQUEST, });
export const createTenantReceive = () => ({ type: types.CREATE_TENANT_RECEIVE, });
export const createTenantFailure = error => ({
    type: types.CREATE_TENANT_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const createTenant = (tenants) => (dispatch) => {
    dispatch(createTenantRequest());

    api.post(api.defaults.ADMIN_TENANT, tenants)
        .then(() => {
            dispatch(createTenantReceive());
            dispatch(getTenants());
            navigate(`/admin/tenants`)
        })
        .catch(error => {
            dispatch(createTenantFailure(error.message));
        })
}


export const deleteTenantRequest = (id) => ({ 
    type: types.DELETE_TENANT_REQUEST,
    payload: {
        id
    }
});
export const deleteTenantReceive = (id) => ({ 
    type: types.DELETE_TENANT_RECEIVE, 
    payload: {
        id
    }
});
export const deleteTenantFailure = (error, id) => ({
    type: types.DELETE_TENANT_FAILURE,
    payload: {
        error,
        id
    }
});

// Thunks
export const deleteTenant = (id) => (dispatch) => {
    dispatch(deleteTenantRequest(id));

    api.delete(`${api.defaults.ADMIN_TENANT}/${id}`)
        .then(() => {
            dispatch(deleteTenantReceive(id));
            dispatch(getTenants());
            navigate(`/admin/tenants`)
        })
        .catch(error => {
            dispatch(deleteTenantFailure(error.message, id));
        })
}


export const editTenantRequest = () => ({ type: types.EDIT_TENANT_REQUEST, });
export const editTenantReceive = () => ({ type: types.EDIT_TENANT_RECEIVE, });
export const editTenantFailure = error => ({
    type: types.EDIT_TENANT_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const editTenant = (tenant) => (dispatch) => {
    dispatch(editTenantRequest());

    api.put(`${api.defaults.ADMIN_TENANT}`, tenant)
        .then(() => {
            dispatch(editTenantReceive());
            dispatch(getTenants());
            navigate(`/admin/tenants`)
        })
        .catch(error => {
            dispatch(editTenantFailure(error.message));
        })
}


export const getTenantRequest = () => ({ type: types.GET_TENANT_REQUEST, });

export const getTenantReceive = tenant => ({
    type: types.GET_TENANT_RECEIVE,
    payload: {
        tenant,
    }
});

export const getTenantFailure = error => ({
    type: types.GET_TENANT_FAILURE,
    payload: {
        error,
    }
});

// Thunks
export const getTenant = (tenantId) => (dispatch) => {
    dispatch(getTenantRequest());

    api.get(`${api.defaults.ADMIN_TENANT}/${tenantId}`)
        .then((response) => {
            const tenant = response.data;
            dispatch(getTenantReceive(tenant));
        })
        .catch(error => {
            dispatch(getTenantFailure(error.message));
        })
}
