import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Own Components
import { Filters, MoreActions } from "./components";

// Components
import { Header, Button, Table } from "components";

// Assets
import styles from "./styles.module.css";

// Actions
import * as actionsEntities from "actions/admin/entities";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function Entities() {

    const dispatch = useDispatch();

    const { tenantId } = useSelector(state => state.admin.tenants);

    const {
        isFetching,
        entities,
        idsBeingDeleted
    } = useSelector(state => state.admin.entities);

    useEffect(() => {
        dispatch(actionsEntities.getEntities(tenantId));
    }, [])

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Entities"
                classes={ HEADER_CLASSES }
                leftChildren={
                    tenantId && (
                        <Button
                            onClick={ () => { navigate('/admin/entities/create') } }
                            className={ styles.button }
                        >
                            Create a new Entity
                        </Button>
                    )                                        
                }
                rightChildren={ <Filters /> }
            />

            <Table
                emptyMessage="No entities found"
                isLoadingData={ isFetching }
                widths={ [150, 300, 200, 200, 200] }
                headItems={ ["Name", "Reporting Level", "Xbrl Reporting Level", "Xbrl Identifier", ""] }
                bodyItems={
                    entities.map(({
                        id,
                        name,
                        reportingLevel,
                        xbrlReportingLevel,
                        xbrlIdentifier
                    }) => ([
                        name,
                        reportingLevel,
                        xbrlReportingLevel,
                        xbrlIdentifier,
                        <MoreActions
                            id={id}
                            name={name}
                            tenantId= {tenantId}
                            isDeleting={ idsBeingDeleted.includes(id) }
                        />
                    ]))
                }
            />
        </main>
    )
}