import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatDate, { DEFAULT_FORMAT } from "utils/formatDate";

// Actions
import { changeReportSelectedPeriod } from "actions/validations";

import styles from "./styles.module.css";

export default function Filters() {
    const dispatch = useDispatch();

    const reportSelectedPeriod = useSelector(state => state.validations.reportSelectedPeriod);
    const periods = useSelector(state => state.validations.reportPeriods);

    const changePeriod = e => {
        dispatch(changeReportSelectedPeriod(e.target.value));
    }

    if (periods && Boolean(periods.length)) {
        return (
            <select
                className={ styles.select }
                onChange={ changePeriod }
                value={ reportSelectedPeriod }
            >
                {
                    periods.map(period => (
                        <option
                            key={ period }
                            value={ period }
                        >
                            { formatDate(period, DEFAULT_FORMAT) }
                        </option>
                    ))
                }
            </select>
        );
    }

    return null;
}
