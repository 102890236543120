import React from 'react';
import { navigate } from "@reach/router";
import { useDispatch } from 'react-redux';

// Utils
import { confirm } from "utils/confirm";

// Actions
import * as actionsEntities from "actions/admin/entities";

// Components
import { More } from "components/Table";

export default function Actions({
    id,
    name,
    tenantId,
    isDeleting
}) {
    const dispatch = useDispatch();

    const viewEntity = () => {
        navigate(`entities/view/${id}`)
    }

    const editEntity = () => {
        navigate(`entities/edit/${id}`)     
    }    

    const deleteEntity = () => {
        confirm({
            title: "Are you sure?",
            description: `Are you sure you want to delete the following Entity: ${name}?`,
        })
        .then((shouldProceed) => {
            if (shouldProceed) {
                dispatch(actionsEntities.deleteEntity(tenantId, id));
            }
        })
    }

    if (isDeleting) {
        return (
            <span style={ { fontSize: ".8rem" } }>Deleting</span>
        );
    }

    return (
        <More
            options={ [
                {
                    type: "button",
                    action: viewEntity,
                    text: "View",
                    icon: (<i className="fa fa-info-circle"/>)
                } ,
                {
                    type: "button",
                    action: editEntity,
                    text: "Edit",
                    icon: (<i className="fa fa-edit"/>)
                } ,
                {
                    type: "button",
                    action: deleteEntity,
                    text: `Delete`,
                    icon: (<i className="fa fa-trash" />)
                }
            ] }
        />
    )
}
