import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Own Components
import { Form, MoreActions } from "./components";

// Components
import { Header, Button, Table } from "components";

// Assets
import styles from "./styles.module.css";

// Actions
import * as actionsTenants from "actions/admin/tenants";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function Tenants() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionsTenants.getTenants());
    }, [])

    const {
        isFetching,
        tenants,
        idsBeingDeleted
    } = useSelector(state => state.admin.tenants);

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Tenants"
                classes={ HEADER_CLASSES }
                leftChildren={
                        <Button
                            onClick={ () => { navigate('/admin/tenants/create') } }
                            className={ styles.button }
                        >
                            Create a new Tenant
                        </Button>                 
                }
            />

            <Table
                emptyMessage="No tenants found"
                isLoadingData={ isFetching }
                widths={ [150, 300, 200, 200, 200] }
                headItems={ ["Name", "Server", "Database", "Username", ""] }
                bodyItems={
                    tenants.map(({
                        id,
                        name,
                        server,
                        databaseName,
                        userName
                    }) => ([
                        name,
                        server,
                        databaseName,
                        userName,
                        <MoreActions
                            id={id}
                            name={name}
                            isDeleting={ idsBeingDeleted.includes(id) }
                        />
                    ]))
                }
            />
        </main>
    )
}