import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actionsAuth from "actions/auth";

import {
    Button
} from "components";
import Card, {
    CardHeader,
    CardContent,
    CardFooter,
    CardDescription,
} from "components/Card";

import styles from "./styles.module.css";

const getTitle = (currentStatus) => {
    if (
        sessionStorage.getItem("username") &&
        currentStatus === "REAUTHENTICATING_USER"
    ) {
        return "Obtaining credentials..."
    }
      
    if (currentStatus === "SIGNING_IN") {
        return "Signing in..."
    }

    return "Welcome to RRH"
}

const getDescription = (currentStatus) => {
    if (
        sessionStorage.getItem("username") &&
        currentStatus === "REAUTHENTICATING_USER"
    ) {
        return null;          
    }
      
    if (currentStatus === "SIGNING_IN") {
        return (
            <CardDescription>
                Please continue the Authentication with Microsoft login pop-up.
            </CardDescription>            
        )            
    }

    return (
        <CardDescription>
            To continue please log into your account.
        </CardDescription>            
    )
}

export default function StatusCard() {
    const dispatch = useDispatch();

    const currentStatus = useSelector(state => state.auth.currentStatus);
    const selectedTenant = useSelector(state => state.tenants.selected);

    const handleSignIn = () => {
        dispatch(actionsAuth.signIn())
        .then(() => dispatch(actionsAuth.getUserPermissions()));
    };

    return (
        <div className={ styles.view }>
            <Card className={ styles.card }>
                <CardHeader
                    title={ getTitle(currentStatus) }
                />
                <CardContent>
                    { getDescription(currentStatus) }
                </CardContent>
                {
                    currentStatus === "EMPTY_AUTH" && (
                        <CardFooter>
                            <Button onClick={ handleSignIn }>
                                Login
                            </Button>
                        </CardFooter>
                    )          
                }
            </Card>
        </div>    
    )
}
