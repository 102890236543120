import api from 'config/api';
import download from 'downloadjs';

export const types = {
    DOWNLOAD_TEMPLATES_REQUEST: "templates/downloadTemplatesRequest",
    DOWNLOAD_TEMPLATES_SUCCEED: "templates/downloadTemplatesSucceed",
    DOWNLOAD_TEMPLATES_FAILURE: "templates/downloadTemplatesFailure",
    GET_PIPELINES_REQUEST: "templates/getPipelinesRequest",
    GET_PIPELINES_SUCCEED: "templates/getPipelinesSucceed",
    GET_PIPELINES_FAILURE: "templates/getPipelinesFailure",
    
    OPEN_DOWNLOAD_TEMPLATE_MODAL: "templates/openDownloadTemplateModal",
    CLOSE_DOWNLOAD_TEMPLATE_MODAL: "templates/closeDownloadTemplateModal",
}
export const openDownloadTemplateModal = () => ({ type: types.OPEN_DOWNLOAD_TEMPLATE_MODAL, });
export const closeDownloadTemplateModal = () => ({ type: types.CLOSE_DOWNLOAD_TEMPLATE_MODAL, });
export const getPipelinesRequest = () => ({ type: types.GET_PIPELINES_REQUEST, });
export const getPipelinesSucceed = pipelines => ({
    type: types.GET_PIPELINES_SUCCEED,
    payload: {
        pipelines
    },
});
export const getPipelinesFailure = (error) => ({
    type: types.GET_PIPELINES_FAILURE,
    payload: {
        error
    }
});
export const getPipelines = () => (dispatch) => {
    dispatch(getPipelinesRequest());
    api.get(`${api.defaults.GET_PIPELINES}`)
        .then((response) => {
            dispatch(getPipelinesSucceed(response.data));
        })
        .catch(error => {
            dispatch(getPipelinesFailure(error.response));
        })
}
export const downloadTemplateRequest = () => ({ type: types.DOWNLOAD_TEMPLATES_REQUEST, });
export const downloadTemplateSucceed = () => ({
    type: types.DOWNLOAD_TEMPLATES_SUCCEED,
});
export const downloadTemplateFailure = (error) => ({
    type: types.DOWNLOAD_TEMPLATES_FAILURE,
    payload: {
        error
    }
});
export const downloadTemplate = (pipeline) => (dispatch) => {
    dispatch(downloadTemplateRequest());
    api.get(`${api.defaults.DOWNLOAD_TEMPLATES}/${pipeline}`)
        .then((response) => {            
            dispatch(downloadTemplateSucceed());
            const { pipelineName, archivedBytes } = response.data;
            const filetype = 'zip'
            const fileName = `${pipelineName}.zip`
            const data = `data:file/${filetype};base64,${archivedBytes}`
            const mimeType = `file/${filetype}`;
            download(data, fileName, mimeType);
        })
        .catch(error => {
            dispatch(downloadTemplateFailure(error.response));
        })
}