import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Table, Badge } from 'react-bootstrap';
import download from 'downloadjs';

// Assets
import styles from "./styles.module.css";

// Actions
import * as actionsLogs from "actions/logs";

// Components
import { LogDetailsText, LogPaginate } from "./components";
import formatDate, { DATE_AND_TIME_FORMAT } from 'utils/formatDate';

export default function LogDetails({ 
    category 
}) {
    const dispatch = useDispatch();
    const [selectedActionId, setSelectedActionId] = useState('');
    const [errorClassName, setErrorClassName] = useState('without_errors');
    const actions = useSelector(state => state.logs.currentPageActions);
    const logs = useSelector(state => state.logs.list);
    const isFetchingLogs = useSelector(state => state.logs.isFetchingLogs);

    useEffect(() => {
        dispatch(actionsLogs.getActionsByCategory(category));
    }, []);

    const getLogs = (actionId, withErrors) => {
        setSelectedActionId(actionId);
        setErrorClassName(withErrors ? 'with_errors' : 'without_errors')
        dispatch(actionsLogs.getLogs(actionId));
    }

    const downloadErrorFile = (errorLogFile) => {
        const filetype = 'xlsx';
        const { fileName, content } = errorLogFile;
        const data = `data:file/${filetype};base64,${content}`
        const mimeType = `file/${filetype}`;
        download(data, fileName, mimeType);
    }

    const filterDescription = (description, errorLogFile) => {
        return errorLogFile && errorLogFile.content ?
        <span>{ description }, Click <span onClick={ () => { downloadErrorFile(errorLogFile) } }>here</span> to download error file.</span>
        :
        <span>{ description }</span>
    }

    return (
        <main className={ styles.view }>
            <h2 className="log-title"> { isFetchingLogs ? 'Loading...' : 'Select an action to see logs' }</h2>
            {
                !isFetchingLogs && (
                    <Row>
                        <Col xs lg={5}>
                            <Row>
                                <Col xs lg={12}>
                                    <Table bordered hover>
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Type</th>
                                                <th>Endpoint</th>
                                                <th>Timestamp</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                actions.map(({
                                                    id,
                                                    user,
                                                    type,
                                                    endpoint,
                                                    timestampEnd,
                                                    withErrors
                                                }) => (
                                                    <tr key={id} onClick={ () => getLogs(id, withErrors) } className={selectedActionId === id ? styles.active : ""}>
                                                        <td>{user}</td>
                                                        <td>{type}</td>
                                                        <td>{endpoint}</td>
                                                        <td>
                                                            {
                                                                formatDate(timestampEnd, DATE_AND_TIME_FORMAT)
                                                            }
                                                        </td>
                                                        <td>{!withErrors ? <Badge variant="success">Success</Badge> : <Badge variant="danger">Failed</Badge>}</td>
                                                    </tr>
                                                ))
                                            }
                                            {
                                                actions.length === 0 && (
                                                    <tr>
                                                        <td colSpan="5" className="text-center">No record found</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs lg={12}>
                                    <LogPaginate></LogPaginate>
                                </Col>
                            </Row>
                        </Col>
                        <Col className={"log_" + errorClassName + " log-body-details"} xs lg={7}>
                            <Row className="log-details-header" >
                                <Col xs lg={2}><code style={{ color: 'white' }}>Section</code> </Col>
                                <Col xs lg={2}><code style={{ color: 'white' }}>SubSection</code> </Col>
                                <Col xs lg={5}><code style={{ color: 'white' }}>Description</code></Col>
                                <Col xs lg={3}><code style={{ color: 'white' }}>Timestamp</code></Col>
                            </Row>

                            <div className='log-details-text-container'>
                                {
                                    logs.map(({id, section, subSection, description, timestamp, errorLogFile}) => (
                                        <LogDetailsText
                                            className={"log_" + errorClassName}
                                            colorCode={errorClassName}
                                            section={section}
                                            subsection={subSection}
                                            description={ filterDescription(description, errorLogFile) }
                                            timestamp={timestamp}
                                            key={id} >
                                        </LogDetailsText>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                )
            }
        </main>
    )
}