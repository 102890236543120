import React, { useRef, useEffect, forwardRef } from "react";
import { createPortal } from "react-dom";
import classnames from "classnames";

import styles from "./styles.module.css";


export default function Modal({
    children,
    onClose: closeModal,
    ...rest
}) {
    const modalRef = useRef();

    const handleTabKey = e => {
        const focusableElements = modalRef.current.querySelectorAll(
            `a[href], button, textarea, input, select`
        );

        const [ firstElement ] = focusableElements;
        const lastElement = focusableElements[focusableElements.length - 1];

        if (!e.shiftKey && document.activeElement === lastElement) {
            firstElement.focus();
            e.preventDefault()
        }

        if (e.shiftKey && document.activeElement === firstElement) {
            lastElement.focus();
            e.preventDefault();
        }
    }

    const handleClick = e => {
        if (e.target === modalRef.current) {
            closeModal()
        }
    }

    const keyListenerMap = new Map([[27, closeModal], [9, handleTabKey]]);

    useEffect(() => {
        function keyListener(e) {
            const listener = keyListenerMap.get(e.keyCode);
            return listener && listener(e);
        }
        document.addEventListener("keydown", keyListener);

        return () => document.removeEventListener("keydown", keyListener);
    });

    return createPortal(
        <div
            className={ styles.modal }
            onClick={ handleClick }
            ref={ modalRef }
            role="dialog"
            aria-modal="true"
            { ...rest }
        >
            { children }
        </div>,
        document.body
    );
}
