import React from 'react';
import formatDate, { PRECISE_DATE_AND_TIME_FORMAT } from "utils/formatDate";

import { Row, Col } from 'react-bootstrap';

export default function LogDetailsText({
    section,
    subsection,
    description,
    timestamp,
    colorCode,
}) {

    return (
        <div >
            <Row >
                <Col className={"log_" + colorCode} xs lg={2}><code>{section}</code> </Col>
                <Col className={"log_" + colorCode} xs lg={2}><code> {subsection}</code> </Col>
                <Col className={"log_" + colorCode} xs lg={5}><code>{description} </code></Col>
                <Col className={"log_" + colorCode} xs lg={3}>
                    <code>
                        {
                            formatDate(timestamp, PRECISE_DATE_AND_TIME_FORMAT)
                        }
                    </code>
                </Col>
            </Row>
        </div>
    );
}
