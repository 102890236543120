import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Components
import { Header, Button } from "components";

// Actions
import * as actionsUsers from "actions/admin/users";

// Assets
import styles from "../styles.module.css";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function UserView({id}) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionsUsers.getUser(id));
    }, [])

    const {
        firstname,
        lastname,
        email,
        superAdmin,
        entityManager,
        coreFiling,
        userType
    } = useSelector(state => state.admin.users.user);


    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="View User"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/users') } }
                        variant="secondary"
                        className={ styles.button }
                    >
                        Back
                    </Button>
                }
            />

            <form className={ styles.form }>
                <div className={styles.formControl}>
                    <label htmlFor="firstname">Firstname</label>
                    <input 
                        type='text'
                        value={firstname}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="lastname">Lastname</label>
                    <input
                        type='text'
                        value={lastname}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="email">Email</label>
                    <input
                        type='text'
                        value={email}
                        disabled={true}
                        autoComplete="off"
                    />
                </div>
                <div className={styles.formControl}>
                    <label htmlFor="userType">User Type</label>
                    <select
                        as="select"
                        disabled={true}
                        value={userType}
                        className={ styles.select }
                    >
                        <option value="all">All</option>
                        <option value="default">Default</option> 
                        <option value="admin">Admin</option>
                    </select>
                </div>
                {
                    (userType == 'admin' || userType == 'all') && (
                        <div className={styles.formControl}>
                            <label className={styles.label} htmlFor="superAdmin">Super Admin</label>
                            
                            <input
                                type='radio'
                                checked={ superAdmin }
                                disabled={true}
                            />
                            <label htmlFor="superAdmin">Yes</label>

                            <input
                                type='radio'
                                checked={ !superAdmin }
                                disabled={true}
                            />
                            <label htmlFor="superAdmin">No</label>
                        </div>
                    )
                }

                {
                    (userType == 'default' || userType == 'all') && (
                        <>
                            <div className={styles.formControl}>
                                <label className={styles.label} htmlFor="entityManager">Entity Manager</label>
                                
                                <input
                                    type='radio'
                                    checked={ entityManager }
                                    disabled={true}
                                />
                                <label htmlFor="entityManager">Yes</label>

                                <input
                                    type='radio'
                                    checked={ !entityManager }
                                    disabled={true}
                                />
                                <label htmlFor="entityManager">No</label>
                            </div>

                            <div className={styles.formControl}>
                                <label className={styles.label} htmlFor="coreFiling">Core Filing</label>

                                <input
                                    type='radio'
                                    checked={ coreFiling }
                                    disabled={true}
                                />
                                <label htmlFor="coreFiling">Yes</label>

                                <input
                                    type='radio'
                                    checked={ !coreFiling }
                                    disabled={true}
                                />
                                <label htmlFor="coreFiling">No</label>
                            </div>
                        </>                        
                    )
                }                 
            </form>
        </main>
    )
}