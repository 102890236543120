import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import * as actionsTenants from "actions/admin/tenants";
import * as actionsEntities from "actions/admin/entities";

// Assets
import styles from "./styles.module.css";

export default function Filters() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionsTenants.getTenants());
    }, [])

    const { tenants, tenantId } = useSelector(state => state.admin.tenants);

    const changeEntity = e => {
        const tenantId = e.target.value;
        dispatch(actionsTenants.selectTenantId(tenantId));
        dispatch(actionsEntities.getEntities(tenantId));
    }

    return (
        <>
            <select
                onChange={ changeEntity }
                value={ tenantId }
                className={ styles.select }
            >
                <option value=''>Select Tenant</option>
                {
                    tenants.map(({
                        id,
                        name
                    }) => (
                        <option value={ id } key={ name }>
                            { name }
                        </option>
                    ))
                }
            </select>
        </>
    )
}
