import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';

// Own Components
import { Reports, Rules, Results } from "./components";

// Actions
import * as actionsValidations from "actions/validations";

// Assets
import styles from "./styles.module.css";

export default function Validations() {
    const dispatch = useDispatch();

    const [key, setKey] = useState('setRules');
    const [reports, setReports] = useState([])
    const [rulesets, setRulesets] = useState([])
    const [isXrblValidation, setIsXrblValidation] = useState(false)

    const manageReportId = (e) => {
        const reportId = e.target.value;
        const isChecked = e.target.checked;
        if(isChecked) {
            setReports(reports => [...reports, reportId])
        } else {
            setReports(reports.filter(report => report !== reportId));
        }
    }

    const manageRuleId = (e) => {
        const ruleId = e.target.value;
        const isChecked = e.target.checked;
        if(isChecked) {
            setRulesets(rulesets => [...rulesets, ruleId])
        } else {
            setRulesets(rulesets.filter(rule => rule !== ruleId));
        }
    }

    const validate = () => {
        const validate = {
            reports,
            rulesets,
            isXrblValidation
        }
        
        dispatch(actionsValidations.validate(validate));
        setKey('results')
    }

    return (
        <div className={ styles.view }>            
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(tab) => setKey(tab)} >
                <Tab eventKey="setRules" title="Initiate validation run" className={ styles.tabContent }>
                    <Row>
                        <Col xs lg={8}>
                            <Reports
                                manageReportId={ manageReportId }
                            />
                        </Col>
                        <Col xs lg={4}>
                            <Rules 
                                validate={validate}
                                manageRuleId={manageRuleId}
                                disableRunBtn={ (!(reports.length > 0 && (rulesets.length > 0 || isXrblValidation))) }
                                setIsXrblValidation={setIsXrblValidation}
                                isXrblValidation={isXrblValidation}
                            />
                        </Col>
                    </Row>                    
                </Tab>
                <Tab eventKey="results" title="Validation results" className={ styles.tabContent }>
                    <Row>
                        <Col xs lg={12}>
                            <Results />
                        </Col>
                    </Row>                    
                </Tab>
            </Tabs>
        </div>
    )
}