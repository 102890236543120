import * as Yup from 'yup'

export const validationSchema = Yup.object({
    objectId: Yup.string().required('objectId field is required'),
    firstname: Yup.string().required('Firstname field is required'),
    lastname: Yup.string().required('Lastname field is required'),
    email: Yup.string()
        .email('Invalid email format')
        .required('Email field is required'),
    userType: Yup.string().required('User Type field is required'),
})