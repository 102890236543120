import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from "@reach/router";

// Own Components
import { Form, MoreActions } from "./components";

// Components
import { Header, Button, Table } from "components";

// Assets
import styles from "./styles.module.css";

// Actions
import * as actionPowerbis from "actions/admin/powerbis";

const HEADER_CLASSES = {
    root: styles.header,
    title: styles.title
}

export default function Powerbis() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actionPowerbis.getPowerbis());
    }, [])

    const {
        isFetching,
        powerbis,
        idsBeingDeleted
    } = useSelector(state => state.admin.powerbis);

    return (
        <main className={ styles.view }>
            <Header
                heading="h1"
                title="Power Bi"
                classes={ HEADER_CLASSES }
                leftChildren={
                    <Button
                        onClick={ () => { navigate('/admin/powerbis/create') } }
                        className={ styles.button }
                    >
                        Create a new power bi
                    </Button>                   
                }
            />

            <Table
                emptyMessage="No record found"
                isLoadingData={ isFetching }
                widths={ [300, 300, 300, 100] }
                headItems={ ["Client Id", "Tenant Id", "Entity Id", ""] }
                bodyItems={
                    powerbis.map(({
                        id,
                        clientId,
                        tenantId,
                        entityId
                    }) => ([
                        clientId,
                        tenantId,
                        entityId
                        ,
                        <MoreActions
                            id={id}
                            isDeleting={ idsBeingDeleted.includes(id) }
                        />
                    ]))
                }
            />
        </main>
    )
}