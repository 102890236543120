import api from 'config/api';

export const types = {
    GET_PIPELINES_REQUEST: "templates/getPipelinesRequest",
    GET_PIPELINES_SUCCEED: "templates/getPipelinesSucceed",
    GET_PIPELINES_FAILURE: "templates/getPipelinesFailure",

    GET_PROCESSORS_REQUEST: "templates/getProcessorsRequest",
    GET_PROCESSORS_SUCCEED: "templates/getProcessorsSucceed",
    GET_PROCESSORS_FAILURE: "templates/getProcessorsFailure",

    GENERATE_TEMPLATE_REQUEST: "templates/generateTemplateRequest",
    GENERATE_TEMPLATE_SUCCEED: "templates/generateTemplateSucceed",
    GENERATE_TEMPLATE_FAILURE: "templates/generateTemplateFailure",

    POPULATE_PIPELINE_REQUEST: "templates/populatePipelineRequest",
    POPULATE_PIPELINE_SUCCEED: "templates/populatePipelineSucceed",
    POPULATE_PIPELINE_FAILURE: "templates/populatePipelineFailure",

    POPULATE_DATAPOINT_REQUEST: "templates/populateDatapointRequest",
    POPULATE_DATAPOINT_SUCCEED: "templates/populateDatapointSucceed",
    POPULATE_DATAPOINT_FAILURE: "templates/populateDatapointFailure",

    GET_SCHEMA_REFS_REQUEST: "templates/getShchemaRefsRequest",
    GET_SCHEMA_REFS_SUCCEED: "templates/getShchemaRefsSucceed",
    GET_SCHEMA_REFS_FAILURE: "templates/getShchemaRefsFailure",
}
export const getPipelinesRequest = () => ({ type: types.GET_PIPELINES_REQUEST, });
export const getPipelinesSucceed = pipelines => ({
    type: types.GET_PIPELINES_SUCCEED,
    payload: {
        pipelines
    },
});
export const getPipelinesFailure = (error) => ({
    type: types.GET_PIPELINES_FAILURE,
    payload: {
        error
    }
});
export const getPipelines = (processor) => (dispatch) => {
    dispatch(getPipelinesRequest());
    api.get(`${api.defaults.GET_ADMIN_PIPELINES}/${processor}`)
        .then((response) => {
            dispatch(getPipelinesSucceed(response.data));
        })
        .catch(error => {
            dispatch(getPipelinesFailure(error.response));
        })
}


export const getProcessorsRequest = () => ({ type: types.GET_PROCESSORS_REQUEST, });
export const getProcessorsSucceed = processors => ({
    type: types.GET_PROCESSORS_SUCCEED,
    payload: {
        processors
    },
});
export const getProcessorsFailure = (error) => ({
    type: types.GET_PROCESSORS_FAILURE,
    payload: {
        error
    }
});
export const getProcessors = () => (dispatch) => {
    dispatch(getProcessorsRequest());
    api.get(`${api.defaults.GET_ADMIN_PROCESSORS}`)
        .then((response) => {
            dispatch(getProcessorsSucceed(response.data));
        })
        .catch(error => {
            dispatch(getProcessorsFailure(error.response));
        })
}

export const generateTemplateRequest = () => ({ type: types.GENERATE_TEMPLATE_REQUEST });
export const generateTemplateSucceed = () => ({ type: types.GENERATE_TEMPLATE_SUCCEED });

export const generateTemplateFailure = (error) => ({
    type: types.GENERATE_TEMPLATE_FAILURE,
    payload: {
        error
    }
});
export const generateTemplate = (pipeline, processor) => (dispatch) => {
    dispatch(generateTemplateRequest());
    api.get(`${api.defaults.GENERATE_TEMPLATE}/${processor}/${pipeline}`)
        .then(() => {
            dispatch(generateTemplateSucceed());
            alert("Generate Templates started (this will 30 minutes to complete).")
        })
        .catch(error => {
            dispatch(generateTemplateFailure(error.response));
            alert("Something went wrong when trying to start generate templates.")
        })
}

export const populatePipelineRequest = () => ({ type: types.POPULATE_PIPELINE_REQUEST, });
export const populatePipelineSucceed = () => ({ type: types.POPULATE_PIPELINE_SUCCEED });
export const populatePipelineFailure = (error) => ({
    type: types.POPULATE_PIPELINE_FAILURE,
    payload: {
        error
    }
});
export const populatePipeline = (processor) => (dispatch) => {
    dispatch(populatePipelineRequest());
    api.get(`${api.defaults.POPULATE_PIPELINE}/${processor}`)
        .then(() => {
            dispatch(populatePipelineSucceed());
        })
        .catch(error => {
            dispatch(populatePipelineFailure(error.response));
        })
}


export const populateDatapointRequest = () => ({ type: types.POPULATE_DATAPOINT_REQUEST, });
export const populateDatapointSucceed = () => ({ type: types.POPULATE_DATAPOINT_SUCCEED });
export const populateDatapointFailure = (error) => ({
    type: types.POPULATE_DATAPOINT_FAILURE,
    payload: {
        error
    }
});
export const populateDatapoint = (pipeline, processor) => (dispatch) => {
    dispatch(populateDatapointRequest());
    api.get(`${api.defaults.POPULATE_DATAPOINT}/${pipeline}/${processor}`)
        .then(() => {
            dispatch(populateDatapointSucceed());
        })
        .catch(error => {
            dispatch(populateDatapointFailure(error.response));
        })
}

export const getSchemaRefsRequest = () => ({ type: types.GET_SCHEMA_REFS_REQUEST, });
export const getSchemaRefsSucceed = schemaRefs => ({
    type: types.GET_SCHEMA_REFS_SUCCEED,
    payload: {
        schemaRefs
    },
});
export const getSchemaRefsFailure = (error) => ({
    type: types.GET_SCHEMA_REFS_FAILURE,
    payload: {
        error
    }
});
export const getSchemaRefsFromPipeline = pipelineName => dispatch => {
    dispatch(getSchemaRefsRequest());
    api.get(`${api.defaults.GET_ADMIN_SCHEMA_REFS}/${pipelineName}`)
        .then((response) => {
            dispatch(getSchemaRefsSucceed(response.data));
        })
        .catch(error => {
            dispatch(getSchemaRefsFailure(error.response));
        })
}
