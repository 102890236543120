import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';

// Assets
import 'font-awesome/css/font-awesome.min.css';
import styles from "./styles.module.css";

export default function Component({
    successStatus,
    description,
    selectCategory,
    category,
    message,
}) {
    const [showA, setShowA] = useState(true);

    const toggleShowA = () => setShowA(prevValue => !prevValue);

    return (
        <Toast
            show={showA}
            className={ styles.toastBody }
            onClose={toggleShowA}
        >
            <Toast.Header className={ styles[successStatus + "_header"] }>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded mr-2"
                    alt=""
                />
                <strong className="mr-auto">
                    {description}
                </strong>
            </Toast.Header>
            <Toast.Body
                onClick={() => selectCategory(category)}
                className="toastr-text"
            >
                { `${message}` }
            </Toast.Body>
        </Toast>
    );
}