import React from 'react';
import doggo from 'assets/imgs/errordoggo.png';

export default function NotFound() {
    return (
        <div className="report-body">
            <div className="error-doggo-container">
                <img className="error-doggo" src={ doggo } />
                <h3 className="report-title-centered">Error 404. Not Found</h3>
            </div>
        </div>
    )
}
